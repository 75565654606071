import React from "react";
import { useState } from "react";
import dateIcon from "../assets/images/date-icon.svg";
import { getHTMLdateFormat } from "../component/common";
// import DatePicker from 'react-datepicker/dist/react-datepicker';

import Cancel from "../assets/images/round_not_qualified.png";
import RightArrow from "../assets/images/right-arrow.svg";
import {
  getAllStateList,
  updateDOBNState,
  getUserProfile,
  sendOTP,
  validateOTP,
  checkEmail,
  addUserInGameName,
  getUserInGameName,
  getUserProfileLite,
  getUserBasicsDetails,
  geWalletDetails,
} from "../apis";

import Advertisement from "../component/advertisement";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validator from "validator";
import moment from "moment";
import Picker from "react-mobile-picker";
import { useTranslation, Trans, withTranslation } from "react-i18next";
import { constants } from "../constants/constants";
import { eventTracking } from "../firebaseAnalytics";
import { events } from "../utils/appEvents";

function generateNumberArray(begin, end) {
  let array = [];
  for (let i = begin; i <= end; i++) {
    array.push((i < 10 ? "0" : "") + i);
  }
  return array;
}
function generateArrayOfYears() {
  var max = new Date().getFullYear() - 18;
  var min = max - 70;
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
}
class DobNStates extends React.Component {
  constructor(props) {
    super(props);
    // console.log(new Date().getDate());
    let month = ("0" + (new Date().getMonth() + 1)).slice(-2);
    let date = ("0" + new Date().getDate()).slice(-2);
    this.state = {
      dobns_data: [],

      state_data: [],
      timer: 30,
      emailTimer: 30,
      time: [],
      phone: "",
      phoneCode: "",
      otp: "",
      emailotp: "",
      email: "",
      dob_value: "",
      state_value: { _id: "", name: "" },
      in_game_name: "",

      tag_phone: false,
      tag_phone_verified: false,
      tag_send_code: false,
      tag_verify_code: false,
      tag_email: false,
      tag_dob: false,
      tag_state: false,
      tag_in_game_name: false,
      tag_state_popup: false,
      tag_email_send_code: false,
      tag_email_verify_code: false,
      tag_email_verified: false,
      isPickerShow: false,
      valueGroups: {
        year: new Date().getFullYear() - 18,
        month: month.toString(),
        day: date.toString(),
      },
      optionGroups: {
        year: generateArrayOfYears(),
        month: generateNumberArray(1, 12),
        day: generateNumberArray(1, 31),
      },
    };

    // this.onChangeInviteCode = this.onChangeInviteCode.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
  }

  componentDidMount() {
    this.count = setInterval(() => {
      if (this.state.timer > 0) {
        this.setState({ timer: this.state.timer - 1 });
      }
    }, 1000);

    this.countEmail = setInterval(() => {
      if (this.state.emailTimer > 0) {
        this.setState({ emailTimer: this.state.emailTimer - 1 });
      }
    }, 1000);

    // console.log("screen: ", this.props.screen);
    var temp_profileLite_data = JSON.parse(
      localStorage.getItem('profileLite')
    );

    // var temp_Basic_data = JSON.parse(
    //   localStorage.getItem(constants.profileBasics)
    // );

    // console.log("temp_dobns_data", temp_profileLite_data);
    var temp_dob_value = temp_profileLite_data?.item?.dateOfBirth;
    var temp_tag_dob = false;

    if (temp_dob_value === null || temp_dob_value === undefined) {
      temp_dob_value = "";
      temp_tag_dob = true;
    }

    // var temp_state_value = temp_Basic_data?.state;
    // var temp_tag_state = false;

    // if (
    //   temp_Basic_data?.country?.code === "IN" &&
    //   (temp_state_value?.name === null || temp_state_value?.name === undefined)
    // ) {
    //   temp_state_value = { _id: "", name: "" };
    //   temp_tag_state = true;
    // }

    // console.log(temp_tag_state);


    this.setState({
      dobns_data: temp_profileLite_data,
      dob_value: temp_dob_value,
      // state_value: temp_state_value,
      tag_dob: temp_tag_dob,
      // tag_state: temp_tag_state,
    });
    // console.log("this.props.screen", this.props.screen);

    if (this.props.screen === "tournaments-contests") {
      var temp_in_game_name = null;
      var temp_game_name = false;

      var tempGameData = JSON.parse(localStorage.getItem("userInGameNames"));
      // console.log(tempGameData)
      if (tempGameData !== null) {
        let index = tempGameData.findIndex(
          (a) => a.game === localStorage.getItem("activeGameId")
        );
        console.log("index", index);
        if (index !== -1) {
          temp_in_game_name = tempGameData[index].userINGameName;
        } else {
          temp_game_name = true;
        }
      } else {
        temp_game_name = true;
      }
      // temp_dobns_data.item?.gameNames?.forEach(element => {
      //   if (element.game === localStorage.getItem('activeGameId'))
      //     temp_in_game_name = element.uniqueIGN;
      // })
      // console.log(temp_in_game_name);
      // if (temp_in_game_name === null || temp_in_game_name === undefined) {
      //   temp_in_game_name = "";
      //   temp_game_name = true;
      // }

      // var temp_game_name = temp_in_game_name === null ||
      //   temp_in_game_name === undefined ? true : false;

      this.setState({
        // tag_phone: false,
        // tag_send_code: false,
        // tag_email_send_code: false,
        in_game_name: temp_in_game_name, // temp_in_game_name
        tag_email: false,
        tag_in_game_name: temp_game_name, // temp_game_name
        tag_email_verified: true, //if popup open for ingame name
        tag_phone_verified: true, //if popup open for ingame name
      });
      // console.log("this.state:189",this.state)
      // var temp_email = temp_dobns_data.item?.email;
      // var temp_email_verified = temp_dobns_data.item?.isEmailVerified;
      // var temp_tag_email = false;
      // var temp_tag_email_verified = false;

      // if (temp_email === null || temp_email === undefined) {
      //   temp_email = "";
      //   temp_tag_email = true;

      // }
      // if (temp_email_verified === false ) {

      //   temp_tag_email = true;
      //    // temp_tag_email_verified = true;
      //        // this.onClickSendCodeEmail()
      //  }

      //  this.setState({

      //     tag_email_send_code: false,
      //     email: temp_email, // temp_email
      //     tag_email: temp_tag_email, // temp_tag_email
      //     tag_email_verified: temp_email_verified, // temp_tag_email_verified
      //   });

      // // phone
      // var temp_phone = temp_dobns_data.item?.phone;
      // var temp_phone_verified = temp_dobns_data.item?.isMobileVerified;
      // var temp_tag_phone = false;
      // var temp_tag_phone_verified = false;

      // console.log(temp_dobns_data.item?.email,temp_dobns_data.item?.isEmailVerified, temp_tag_email)
      // console.log(temp_dobns_data.item?.phone,temp_dobns_data.item?.isMobileVerified)

      // if (temp_phone === null || temp_phone === undefined) {
      //   temp_phone = "";
      //   temp_tag_phone = true;

      // }
      // if (temp_phone_verified === false ) {

      //   temp_tag_phone = true;
      //    // temp_tag_email_verified = true;
      //        // this.onClickSendCodeEmail()
      //  }
      //  console.log(temp_tag_phone)
      //  this.setState({

      //   tag_phone_send_code: false,
      //   phone: temp_phone, // temp_phone
      //   tag_phone: temp_tag_phone, // temp_tag_phone
      //   tag_phone_verified: temp_phone_verified, // temp_tag_phone_verified
      // });
    } else if (
      this.props.screen === "coin-store-buy-item" ||
      this.props.screen === "coin-store-redeem-item" ||
      this.props.screen === "subscription-buy-pack"
    ) {
      var temp_email = temp_profileLite_data.item?.email;
      var temp_email_verified = temp_profileLite_data.item?.isEmailVerified;
      var temp_tag_email = false;
      var temp_tag_email_verified = false;

      if (temp_email === null || temp_email === undefined) {
        temp_email = "";
        temp_tag_email = true;
      }
      if (temp_email_verified === false) {
        temp_tag_email = true;
        // temp_tag_email_verified = true;
        // this.onClickSendCodeEmail()
      }

      this.setState({
        tag_email_send_code: false,
        email: temp_email, // temp_email
        tag_email: temp_tag_email, // temp_tag_email
        tag_email_verified: temp_email_verified, // temp_tag_email_verified
      });

      // phone
      var temp_phone = temp_profileLite_data.item?.phone;
      var temp_phone_verified = temp_profileLite_data.item?.isMobileVerified;
      var temp_tag_phone = false;
      var temp_tag_phone_verified = false;

      // console.log("temp_phone_verified", temp_phone_verified);
      // console.log(temp_dobns_data.item?.email, temp_dobns_data.item?.isEmailVerified, temp_tag_email)
      // console.log(
      //   temp_profileLite_data.item?.phone,
      //   temp_profileLite_data.item?.isMobileVerified
      // );

      if (temp_phone === null || temp_phone === undefined) {
        temp_phone = "";
        temp_tag_phone = true;
      }
      if (temp_phone_verified === false) {
        temp_tag_phone = true;
        temp_tag_phone_verified = true;
        // this.onClickSendCodeEmail()
      }
      // console.log("temp_tag_phone", temp_tag_phone);
      this.setState({
        tag_phone_send_code: false,
        phone: temp_phone, // temp_phone
        tag_phone: temp_tag_phone, // temp_tag_phone
        tag_phone_verified: temp_phone_verified, // temp_tag_phone_verified
        tag_in_game_name: false,
      });
      // var temp_email = temp_dobns_data.item?.email;
      // console.log("temp_email==>", temp_email);
      // var temp_phone_verified = temp_dobns_data.item?.isMobileVerified;
      // console.log("temp_phone_verified==>", temp_phone_verified);
      // var temp_tag_phone = false;
      // console.log("temp_tag_phone==>", temp_tag_phone);
      // var temp_tag_phone_verified = false;
      // console.log("temp_tag_phone==>", temp_tag_phone);

      // if (temp_phone === null || temp_phone === undefined) {
      //   temp_phone = "";
      //   temp_tag_phone = true;

      // }

      // if (temp_phone_verified === false ) {
      //  console.log("check phone is varified or not",temp_phone)
      //  temp_tag_phone = true;
      //   // temp_tag_email_verified = true;
      //       // this.onClickSendCodeEmail()
      // }
      // this.setState({
      //   tag_phone: temp_tag_phone,
      //   tag_send_code: false,
      //   tag_phone_verified: temp_tag_phone_verified, // temp_tag_email_verified
      // });
      // if (temp_email === null || temp_email === undefined) {
      //   temp_email = "";
      //   temp_tag_email = true;

      // }

      // if (temp_email_verified === false ) {
      //  console.log("check email is varified or not",temp_email)
      //  temp_tag_email = true;
      //   // temp_tag_email_verified = true;
      //       // this.onClickSendCodeEmail()
      // }
      // console.log("206", temp_tag_email)
      // // var temp_tag_email = temp_email === null ||
      // //   temp_email === undefined ? true : false;

      // this.setState({

      //   tag_email_send_code: false,
      //   email: temp_email, // temp_email
      //   tag_email: temp_tag_email, // temp_tag_email
      //   tag_email_verified: temp_tag_email_verified, // temp_tag_email_verified
      // });
      // console.log("this.state:229",this.state)
      // console.log(temp_dobns_data.item,this.props.screen )
      // if (this.props.screen === "coin-store-redeem-item") {
      //   var temp_phone = temp_dobns_data.item?.phone;
      //   var temp_tag_phone = false;
      //   var temp_tag_email_verified = false;
      //   var temp_email = temp_dobns_data.item?.email;
      //   console.log("temp_email==>", temp_email);
      //   var temp_email_verified = temp_dobns_data.item?.isEmailVerified;
      //   var temp_phone_verified = temp_dobns_data.item?.isMobileVerified;
      //   console.log("temp_email_verified==>", temp_email_verified);

      //   if (
      //     temp_phone === null ||
      //     temp_phone === undefined ||
      //     temp_phone_verified === false
      //   ) {
      //     temp_phone = "";
      //     temp_tag_phone = true;
      //   }
      //   // if (temp_email_verified === false && temp_email !== "") {
      //   //   temp_tag_email_verified = true;
      //   // }
      //   console.log("temp_tag_email_verified==>", temp_tag_email_verified);

      //   this.setState({
      //     phoneCode: temp_dobns_data.item?.phoneCode,
      //     phone: temp_dobns_data.item?.phone,
      //     tag_phone: temp_tag_phone,
      //     tag_send_code: temp_tag_phone,
      //     tag_email_send_code: temp_tag_email_verified,
      //     tag_email_verified: temp_tag_email_verified, // temp_tag_email_verified
      //   });
      //   console.log("this.state:254",this.state)
      // }
    }

    // this.getAllStateListAPICall();
  }

  onClickSubmit() {
    const { t } = this.props;

    // console.log("hey");
    var check = true;
    // console.log("error", "Please enter valid email address!");

    if (
      this.state.tag_email &&
      (this.state.email === "" || !validator.isEmail(this.state.email))
    ) {
      check = false;
      this.showError(t('error_Please_enter_a_valid_email_address'));
    }

    if (this.state.tag_phone) {
      check = false;
      this.showError(t('error_please_verify_mobile_number'));
    }

    if (this.state.tag_dob && this.state.dob_value === "") {
      check = false;

      this.showError(t('error_please_enter_your_date_of_birth'));
    }

    if (this.state.tag_state && this.state.state_value.name === "") {
      check = false;
      this.showError(t('error_please_enter_your_state'));
    }
    // console.log(this.state.tag_in_game_name, this.state.in_game_name);
    if (
      this.state.tag_in_game_name &&
      (this.state.in_game_name === "" || this.state.in_game_name == null)
    ) {
      check = false;
      this.showError(t('success_Please_enter_Gamerji_username'));
    }

    if (check) {
      // console.log(this.state.email);
      // console.log(this.state.dob_value);
      // console.log(this.state.state_value);
      // console.log(this.state.in_game_name);

      var payload = {
        gamerjiName: this.state.dobns_data.item?.gamerjiName,
      };

      if (this.state.tag_email) {
        payload.email = this.state.email;
      }

      // if(this.state.tag_dob) {
      // payload.dateOfBirth =
      //   this.state.valueGroups.year +
      //   "-" +
      //   this.state.valueGroups.month +
      //   "-" +
      //   this.state.valueGroups.day;
      // }
      payload.dateOfBirth = getHTMLdateFormat(this.state.dob_value);
      if (this.state.tag_state) {
        payload.address = {
          state: this.state.state_value._id,
        };
      }

      if (this.state.tag_in_game_name) {
        let temp_game_names = this.state.dobns_data.item?.gameNames;

        temp_game_names?.push({
          game:
            localStorage.getItem("activeGameId") ||
            JSON.parse(localStorage.getItem("scrimData"))?.gameType,
          uniqueIGN: this.state.in_game_name,
        });
        payload.gameNames = temp_game_names;
      }
      //  console.log("******************payload",payload,this.state.in_game_name)

      this.addIngameName(this.state.in_game_name);
      this.updateDOBNStateAPICall(payload);
    }
  }
  async onClickSendCodeEmail() {
    // console.log("hey");
    var check = true;
    const { t } = this.props;

    // console.log("this.state:345", this.state);
    // !this.state.tag_email_send_code &&
    // !this.state.tag_email_verify_code
    if (this.state.email === "") {
      check = false;
      this.showError(t('error_please_enter_your_email'));
    } 
    else if (
      this.state.tag_email &&
      (this.state.email === "" || !validator.isEmail(this.state.email))
    ) {
      check = false;
      this.showError(t('error_Please_enter_a_valid_email_address'));
    }
    else if (check) {
      // console.log(this.state.email);
      var payload = {
        email: this.state.email,
        type: "otpRequest",
      };
      // this.sendEmailOTPAPICall(payload);
      let response = await checkEmail(payload);

      if (response) {
        // console.log("sendOTP: ", response);
        if (response?.success) {
          // this.setState({
          //   tag_email_send_code: false,
          //   // tag_email_verify_code: true,
          //    tag_email_verify_code: true,
          // });
          this.setState({
            tag_email_send_code: true,
            tag_email_verify_code: true,
            emailTimer: 30,
          });
          // console.log("this.state:148", this.state);
        } else this.showError(response?.data?.errors?.[0]?.msg);
      }
    }
  }

  handleInputChange = (e) => {
    // console.log("email otp", e.target.value );
    this.setState({ emailotp: e.target.value });
    const inputValue = e.target.value.trim();

    if (inputValue.length === 6) {
      this.onClickVerifyEmailCode(inputValue);
    }

    this.setState({ inputValue });
    // console.log("this.state:375", this.state);
  };
  handleInputChangeOTP = (e) => {
    console.log(e.target.value);
    this.setState({ otp: e.target.value });
    const inputValue = e.target.value;
    // console.log(inputValue.length);
    setTimeout(() => {
      if (inputValue.length === 6) {
        this.onClickVerifyCode();
      }
    }, 1500);

    this.setState({ inputValue });
    // console.log("this.state:389", this.state);
  };

  onClickSendCode() {
    const { t } = this.props;

    this.setState({ tag_send_code: true, tag_verify_code: false, timer: 30 });
    // console.log("this.state:394", this.state);
    // console.log("D");
    var check = true;
    if (this.state.tag_phone && this.state.phone === "") {
      check = false;
      this.showError(t('error_please_enter_your_phone'));
    }

    if (check) {
      // console.log(this.state.phone);

      var payload = {
        phone: this.state.phone,
        phoneCode: this.state.phoneCode,
      };

      this.sendOTPAPICall(payload);
    }
  }

  onClickVerifyEmailCode(emailotp) {
    const { t } = this.props;

    console.log("this.state.emailotp", emailotp);
    var check = true;
    if (this.state.tag_verify_code && emailotp === "") {
      check = false;
      this.showError(t('error_enter_code'));
    }

    if (check) {
      // console.log(this.state.otp);

      var payload = {
        email: this.state.email,
        otp: emailotp,
        type: "validateOTP",
      };

      this.validateEmailOTPAPICall(payload);
    }
  }
  onClickVerifyCode() {
    const { t } = this.props;

    var check = true;
    if (this.state.tag_verify_code && this.state.otp === "") {
      check = false;
      this.showError(t('error_enter_code'));
    }

    if (check) {
      // console.log(this.state.otp);

      var payload = {
        phone: this.state.phone,
        phoneCode: this.state.phoneCode,
        otp: this.state.otp,
      };

      this.validateOTPAPICall(payload);
    }
  }

  async getAllStateListAPICall() {
    let response = await getAllStateList({
      skip: 0,
      limit: 100,
      sortBy: "name",
      sort: "asc",
    });

    console.log("State List: ", response);
    if (response && "list" in response)
      this.setState({ state_data: response.list });
    // console.log("this.state:465", this.state)
  }

  async sendOTPAPICall(payload) {
    let response = await sendOTP(payload);
    console.log("response", response);
    if (response) {
      // console.log('sendOTP: ', response);
      if (response?.success === true) {
        this.setState({
          tag_send_code: false,
          tag_verify_code: true,
          tag_phone_verified: true,
        });
        console.log("this.state:475", this.state);
      } else this.showError(response?.data?.errors?.[0]?.msg);
    }
  }

  async sendEmailOTPAPICall(payload) {
    let response = await checkEmail(payload);

    if (response) {
      console.log("sendOTP: ", response);
      if (response) {
        this.setState({
          tag_email_send_code: false,
          // tag_email_verify_code: true,
          tag_email_verify_code: true,
        });
        console.log("this.state:148", this.state);
      } else this.showError(response?.data?.errors?.[0]?.msg);
    }
  }

  async validateOTPAPICall(payload) {
    const { t } = this.props;

    let response = await validateOTP(payload);

    if (response) {
      console.log("validateOTP: ", response?.success);
      if (response?.success === true) {
        // this.setState({ tag_verify_code: false, tag_phone_verified:true });
        this.setState({
          tag_phone_send_code: false,
          tag_verify_code: false,
          tag_phone_verified: true,
        });
        console.log("this.state:502", this.state);
        // this.showSuccess("Code is verified!!");
        let profileLiteResponse = await getUserProfileLite();
        if (profileLiteResponse) {
          let profileBasicResposne = await getUserBasicsDetails();
          if (profileBasicResposne) {
            localStorage.setItem(constants.profileBasics, JSON.stringify(profileBasicResposne));
          }
          console.log("getUserProfile :", profileLiteResponse);
          localStorage.setItem(constants.profileLite, JSON.stringify(profileLiteResponse));
          this.setState({ tag_phone: false });
          console.log("this.state:510", this.state);
          if (
            !this.state.tag_phone &&
            !this.state.tag_dob &&
            !this.state.tag_state &&
            !this.state.tag_in_game_name
          ) {
            // if (!this.state.tag_email_verified)
            this.props.submitDobNstate(false);
          }

        }
        else {
          this.showError(t('error_enter_required_details'));
        }
        this.showSuccess(t('code_is_verified'));
      } else {
        this.showError(response?.data?.errors?.[0]?.msg);
      }
    }
  }

  async validateEmailOTPAPICall(payload) {
    const { t } = this.props;

    let response = await checkEmail(payload);

    if (response) {
      console.log("validateOTP: ", response);
      if (response?.success === true) {
        this.setState({
          tag_email_send_code: false,
          tag_email_verify_code: false,
          tag_email_verified: true,
        });

        let profileLiteResponse = await geWalletDetails();
        if (profileLiteResponse) {
          // let profileBasicResposne = await getUserBasicsDetails();
          // if (profileBasicResposne) {
          //   localStorage.setItem(constants.profileBasics, JSON.stringify(profileBasicResposne));
          // }
          localStorage.setItem('profileLite', JSON.stringify(profileLiteResponse));

          this.setState({ tag_email: false, tag_email_verified: true });
          console.log(
            !this.state.tag_email,
            !this.state.tag_dob,
            // !this.state.tag_state,
            !this.state.tag_in_game_name
          );
          if (
            !this.state.tag_email &&
            !this.state.tag_dob &&
            // !this.state.tag_state &&
            !this.state.tag_in_game_name
          ) {
            this.props.submitDobNstate(false);
          }
        } else {
          this.showError(t('error_enter_required_details'));
        }
        this.showSuccess(t('code_is_verified'));
      } else {
        this.showError(response?.data?.errors?.[0]?.msg);
      }
    }
  }

  async updateDOBNStateAPICall(data) {
    const { t } = this.props;

    let response = await updateDOBNState(data);
    console.log("DOBNState: ", response);
    let profileLiteResponse = await getUserProfileLite();
    if (profileLiteResponse) {
      localStorage.setItem(constants.profileLite, JSON.stringify(profileLiteResponse));
    } else {
      this.showError(t('error_enter_required_details'));
    }
  }

  async addIngameName(name) {
    console.log("game id", this.props);
    var user_data = JSON.parse(localStorage.getItem('profileLite'));
    eventTracking(events.gamenamebox,
      {EP_GameName:localStorage.getItem("activeGameName"),
       EP_GameID:localStorage.getItem('activeGameId'),
       EP_AddINGame:name});
    let payload = {
      // gameNames: gameNames
      user: user_data?.item?.user_id,
      game: localStorage.getItem("activeGameId"),
      userINGameName: name,
    };
    // this.props.setcurrentName(name)
    let res = await addUserInGameName(payload);
    if (res) {
      this.GetInGameNames();
    }
  }

  async GetInGameNames() {
    var user_data = JSON.parse(localStorage.getItem('profileLite'));

    let payload = {
      search: {
        user: user_data?.item?.user_id,
      },
    };

    let userInGameNameResponse = await getUserInGameName(payload);
    // console.log("userInGameNameResponse", userInGameNameResponse);
    if (userInGameNameResponse?.success)
      localStorage.setItem(
        "userInGameNames",
        JSON.stringify(userInGameNameResponse?.data)
      );
    // console.log("submitprops>>>>>>>>>", this.props.submitDobNstate);
    this.props.submitDobNstate(false);
  }

  showError(error) {
    toast.error(error, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  showSuccess(error) {
    toast.success(error, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  gobacktoHome() {
    this.props.cancelDobNstate();
  }

  createStateInput() {
    return (
      <div className="dobns_state_div">
        <div className="dobns_state_popup">
          <div className="dobns_header">
            <div className=""></div>
            <div className="dobns_header_text">Select State</div>
            <div className="dobns_cancel_icon">
              <img
                src={Cancel}
                onClick={(e) => {
                  this.setState({ tag_state_popup: false });
                }}
                alt="cancel"
              />
            </div>
          </div>

          <div className="dobns_state_body">
            <div className="dobns_state_body_scrollable_component">
              {this.state.state_data.map((value, i) => {
                return (
                  <div
                    className="dobns_state_list_text"
                    key={i}
                    onClick={() => {
                      this.setState({ state_value: value });
                      this.setState({ tag_state_popup: false });
                    }}
                  >
                    {value.name}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
  handleChange = (name, value) => {
    console.log(name, value);
    let selectedDate = { ...this.state.valueGroups, [name]: value };
    this.setState({
      dob_value:
        selectedDate.year + "-" + selectedDate.month + "-" + selectedDate.day,
    });
    this.setState(({ valueGroups, optionGroups }) => {
      const nextState = {
        valueGroups: {
          ...valueGroups,
          [name]: value,
        },
      };
      if (name === "year" && valueGroups.month === "02") {
        const yearValue = parseInt(value);
        if (
          (yearValue % 4 === 0 && yearValue % 100 !== 0) ||
          yearValue % 400 === 0
        ) {
          nextState.optionGroups = {
            ...optionGroups,
            day: generateNumberArray(1, 29),
          };
        } else {
          nextState.optionGroups = {
            ...optionGroups,
            day: generateNumberArray(1, 28),
          };
        }
      } else if (name === "month") {
        if (value === "02") {
          nextState.optionGroups = {
            ...optionGroups,
            day: generateNumberArray(1, 28),
          };
        } else if (
          ["01", "03", "05", "07", "08", "10", "12"].indexOf(value) > -1 &&
          ["01", "03", "05", "07", "08", "10", "12"].indexOf(
            valueGroups.month
          ) < 0
        ) {
          nextState.optionGroups = {
            ...optionGroups,
            day: generateNumberArray(1, 31),
          };
        } else if (
          ["01", "03", "05", "07", "08", "10", "12"].indexOf(value) < 0 &&
          ["01", "03", "05", "07", "08", "10", "12"].indexOf(
            valueGroups.month
          ) > -1
        ) {
          nextState.optionGroups = {
            ...optionGroups,
            day: generateNumberArray(1, 30),
          };
        }
      }
      return nextState;
    });
  };

  togglePicker = () => {
    this.setState(({ isPickerShow }) => ({
      isPickerShow: !isPickerShow,
    }));
  };

  render() {
    const { isPickerShow, optionGroups, valueGroups } = this.state;
    const maskStyle = {
      display: isPickerShow ? "block" : "none",
    };
    const pickerModalClass = `picker-modal${isPickerShow ? " picker-modal-toggle" : ""
      }`;
    const { t } = this.props;
    return (
      <div className="dobns_main_div">
        {/* <ToastContainer /> */}
        {/* { console.log(this.state.tag_email,
                  this.state.tag_dob ,
                  this.state.tag_state ,
                  this.state.tag_in_game_name,
                  this.state.tag_email_verified)} */}
        {this.state.tag_state_popup === true ? this.createStateInput() : ""}
        {/* {console.log("mail",this.state.tag_email,
          "phone",this.state.tag_phone ,
          "dob",this.state.tag_dob ,
         "state" ,this.state.tag_state ,
         "in-name-game", this.state.tag_in_game_name ,"email ver",
          this.state.tag_email_verified)} */}
        {this.state.tag_email ||
          this.state.tag_phone ||
          this.state.tag_dob ||
          this.state.tag_state ||
          this.state.tag_in_game_name ? (
          // || this.state.tag_email_verified
          <div className="dobns_popup">
            <div className="dobns_header">
              <div className=""></div>
              <div className="dobns_header_text">{t('basic_profile.basic_profile')}</div>
              <div className="dobns_cancel_icon">
                <img
                  src={Cancel}
                  onClick={(e) => {
                    this.gobacktoHome();
                  }}
                />
              </div>
            </div>

            <div className="dobns_body">
              <div className="all_sub_screens_div_related_to_ads">
                <div className="dobns_body_scrollable_component">
                  {/* Date Of Birth */}
                  {this.state.tag_dob ? (
                    <div className="dobns_basic_profile_inputs">
                      <div className="dobns_text">
                        {t('basic_profile.dob')}
                      </div>
                      <div
                        className="dobns_input_component"
                        onClick={this.togglePicker}
                      >
                        <input
                          className="dobns_input"
                          type="date"
                          max={moment()
                            .subtract(18, "years")
                            .format("YYYY-MM-DD")}
                          placeholder={t('error_Select_date_of_birth')}
                          // disabled
                          // value={this.state.dob_value}
                          // onChange={this.handleChange}
                          onChange={(e) => {
                            this.setState({ dob_value: e.target.value });
                          }}
                          value={getHTMLdateFormat(this.state.dob_value)}
                        />
                        <div className="dobns_icon">
                          {/* <img src={dateIcon} alt="n" /> */}
                        </div>
                      </div>
                      {/* <div className="picker-modal-container" style={maskStyle}>
                      <div className="picker-modal-mask"  ></div>
                      <div className={pickerModalClass}>
                        <div className="ios-datepicker">
                          <div className="ios-datepicker-title">Select Date of Birth</div>
                          <a href="javascript:;" onClick={this.togglePicker}>Confirm</a>
                        </div>
                        <Picker
                          optionGroups={optionGroups}
                          valueGroups={valueGroups}
                          onChange={this.handleChange} />
                      </div>
                    </div> */}
                      <div className="dobns_error_div">
                        <div
                          id="dobns_error_div_dob"
                          style={{ display: "none" }}
                        >
                          {t('please_enter_your_date_of_birth')}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* {this.state.tag_state ? (
                    <div className="dobns_basic_profile_inputs">
                      <div className="dobns_text">{t('basic_profile.where_are_you_from')}</div>
                      <div
                        className="dobns_input_component"
                        style={{ cursor: "pointer", height: "51px" }}
                        onClick={() => {
                          this.setState({ tag_state_popup: true });
                        }}
                      >
                        <div className="dobns_input">
                          {this.state.state_value.name === "" ||
                            this.state.state_value.name === undefined ||
                            this.state.state_value.name === null
                            ? t('select_your_state')
                            : this.state.state_value.name}
                        </div>
                        <div className="dobns_icon">
                          <svg
                            className=""
                            width="25"
                            height="25"
                            viewBox="0 0 45 45"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              id=""
                              d="M34 18.34a2 2 0 0 0-2.82 0L24 25.42l-7.08-7.08a2 2 0 1 0-2.82 2.84l8.48 8.48a2.003 2.003 0 0 0 2.19.438c.243-.101.464-.25.65-.438L34 21.18a2 2 0 0 0 0-2.84z"
                              fill="#D3D3D3"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <div className="dobns_error_div">
                        <div
                          id="dobns_error_div_state"
                          style={{ display: "none" }}
                        >
                          {t('please_enter_your_state')}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )} */}
                  {/* Phone */}
                  {/* {console.log(
                    "phone numvber",
                    this.state.tag_phone,
                    this.state.tag_send_code,
                    this.state.tag_verify_code
                  )} */}
                  {
                    this.state.tag_phone && (
                      // (this.state.tag_send_code || this.state.tag_verify_code) ? (
                      <div className="dobns_basic_profile_inputs">
                        <div className="dobns_text">{t('basic_profile.what_your_phone')}</div>
                        <div className="dobns_input_component">
                          <div
                            className="pr-2 border-right"
                            style={{ fontSize: "18px" }}
                          >
                            {this.state.phoneCode}
                          </div>
                          <input
                            className="dobns_input pl-2"
                            type="number"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            placeholder={t('enter_your_phone')}
                            onChange={(e) => {
                              this.setState({ phone: e.target.value });
                            }}
                            value={this.state.phone}
                            disabled={
                              this.state.tag_send_code &&
                              this.state.tag_verify_code
                              // this.state.tag_verify_code
                            }
                          />
                          <div
                            className=" verify "
                            onClick={() => this.onClickSendCode()}
                          >
                            {t('basic_profile.verify')}
                          </div>
                        </div>
                        <div className="dobns_error_div">
                          <div
                            id="dobns_error_div_game_name"
                            style={{ display: "none" }}
                          >
                            {t('please_enter_phone')}
                          </div>
                        </div>
                      </div>
                    )
                    // ) : (
                    //   ""
                    // )
                  }

                  {/* OTP */}
                  {/* {console.log("OTP",this.state.tag_verify_code)} */}
                  {this.state.tag_verify_code ? (
                    <div className="dobns_basic_profile_inputs">
                      <div className="dobns_text">{t('basic_profile.verification_code')}</div>
                      <div className="dobns_input_component">
                        <input
                          className="dobns_input"
                          type="text"
                          placeholder={t('enter_verification_code')}
                          maxLength="6"
                          onChange={this.handleInputChangeOTP}
                          value={this.state.otp}
                        />
                      </div>
                      <div className="dobns_error_div">
                        <div
                          id="dobns_error_div_game_name"
                          style={{ display: "none" }}
                        >
                          {t('please_enter_verification_code')}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* {this.state.tag_send_code && (
                  <button
                    className="dobns_button mt-0 mb-2"
                    onClick={() => this.onClickSendCode()}
                  >
                    Send Codeeee{" "}
                    <img src={RightArrow} alt="" className="mCS_img_loaded" />
                  </button>
                )} */}

                  {/* {this.state.tag_verify_code && (
                  <button
                    className="dobns_button mt-0 mb-2"
                    onClick={() => this.onClickVerifyCode()}
                  >
                    Verify Code{" "}
                    <img src={RightArrow} alt="" className="mCS_img_loaded" />
                  </button>
                )} */}

                  {this.state.tag_verify_code && (
                    <div>
                      <p
                        className="text-center"
                        style={{ marginBottom: "0.5rem" }}
                      >
                        {t('didn_receive_the_verification_code')}
                      </p>
                      {this.state.timer === 0 ? (
                        <p className="text-center">
                          <a
                            className="text-secondary pointer"
                            onClick={() => this.onClickSendCode()}
                          >
                            <b>
                              <u>{t('resend_verification_code')}</u>
                            </b>
                          </a>
                        </p>
                      ) : (
                        <p className="text-center">
                          {t('request_for_a_new_one_in_seconds', { timer: this.state.timer })}
                        </p>
                      )}
                    </div>
                  )}
                  {/* {console.log("🚀 ~ file: dobnstate.js:1037 ~ DobNStates ~ render ~ this.state.tag_email_verify_code3 :", this.state.tag_email_verify_code )} */}
                  {/* {this.state.tag_email_verified &&
                (this.state.tag_email_send_code ||
                  this.state.tag_email_verify_code) ? ( */}
                  {/* {console.log(this.state.tag_email)} */}
                  {this.state.tag_email && (
                    <div className="dobns_basic_profile_inputs">
                      <div className="dobns_text">{t('Profile.email')} </div>
                      <div className="dobns_input_component">
                        <input
                          className="dobns_input pl-2"
                          type="text"
                          placeholder={t('Profile.enter_your_email_id')}
                          onChange={(e) => {
                            this.setState({ email: e.target.value });
                          }}
                          value={this.state.email}
                        // disabled={
                        //   !this.state.tag_email_send_code &&
                        //   !this.state.tag_email_verify_code
                        // }
                        // disabled={true}
                        />
                        {!this.state.tag_email_send_code && (
                          <div
                            className=" verify "
                            onClick={() => this.onClickSendCodeEmail()}
                          >
                            {t('basic_profile.verify')}
                          </div>
                        )}
                        {/* <div
                        className=" verify "
                        onClick={() => this.onClickSendCodeEmail()}
                      >
                        Verify
                      </div> */}
                      </div>
                      <div className="dobns_error_div">
                        <div
                          id="dobns_error_div_game_name"
                          style={{ display: "none" }}
                        >
                          {t('basic_profile.please_enter_email')}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* // ) : (
                //   ""
                // )} */}
                  {/* {console.log("🚀 ~ file: dobnstate.js:1037 ~ DobNStates ~ render ~ this.state.tag_email_verify_code :", this.state.tag_email_verify_code )} */}
                  {/* OTP */}
                  {/* {console.log('heelo', this.state.tag_email_send_code)} */}
                  {this.state.tag_email_send_code ? (
                    <div className="dobns_basic_profile_inputs">
                      <div className="dobns_text">{t('basic_profile.verification_code')}</div>
                      <div className="dobns_input_component">
                        <input
                          className="dobns_input"
                          type="text"
                          placeholder={t('enter_verification_code')}
                          // onChange={(e) => {
                          //   this.setState({ emailotp: e.target.value });
                          // }}
                          onChange={this.handleInputChange}
                          maxLength="6"
                          value={this.state.emailotp}
                        />
                      </div>
                      <div className="dobns_error_div">
                        <div
                          id="dobns_error_div_game_name"
                          style={{ display: "none" }}
                        >
                          {t('please_enter_verification_code')}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* {this.state.tag_email_send_code && (
                  <button
                    className="dobns_button mt-0 mb-2"
                    onClick={() => this.onClickSendCodeEmail()}
                  >
                    Send Codeee{" "}
                    <img src={RightArrow} alt="" className="mCS_img_loaded" />
                  </button>
                )} */}

                  {/* {this.state.tag_email_verify_code && (
                  <button
                    className="dobns_button mt-0 mb-2"
                    onClick={() => this.onClickVerifyEmailCode()}
                  >
                    Verify Code333{" "}
                    <img src={RightArrow} alt="" className="mCS_img_loaded" />
                  </button>
                )} */}

                  {this.state.tag_email_verify_code && (
                    <div>
                      <p
                        className="text-center"
                        style={{ marginBottom: "0.5rem" }}
                      >
                        {t('didn_receive_the_verification_code')}
                      </p>
                      {this.state.emailTimer === 0 ? (
                        <p className="text-center">
                          <a
                            className="text-secondary pointer"
                            onClick={() => this.onClickSendCodeEmail()}
                          >
                            <b>
                              <u>{t('resend_verification_code')}</u>
                            </b>
                          </a>
                        </p>
                      ) : (
                        <p className="text-center">
                          {t('request_for_a_new_one_in_seconds', { timer: this.state.emailTimer })}
                        </p>
                      )}
                    </div>
                  )}

                  {/* Email */}
                  {/* {this.state.tag_email ? (
                  <div className="dobns_basic_profile_inputs">
                    <div className="dobns_text">Email</div>
                    <div className="dobns_input_component">
                      <input
                        className="dobns_input"
                        type="email"
                        placeholder={`Enter your email`}
                        onChange={(e) => {
                          this.setState({ email: e.target.value });
                        }}
                        value={this.state.email}
                      />
                    </div>
                    <div className="dobns_error_div">
                      <div
                        id="dobns_error_div_game_name"
                        style={{ display: "none" }}
                      >
                        Please enter game name
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )} */}

                  {/* State */}

                  {/* Active Game Name */}
                  {this.state.tag_in_game_name ? (
                    <div className="dobns_basic_profile_inputs">
                      <div className="dobns_text">
                        <span>
                          {" "}
                          {t('basic_profile.game_name', { game: localStorage.getItem("activeGame") })}
                        </span>
                        {/* Your {localStorage.getItem("activeGame")} Name */}
                      </div>
                      <div className="dobns_input_component">
                        <input
                          className="dobns_input"
                          type="text"
                          placeholder={t('enter_your_game_name', {
                            game: localStorage.getItem(
                              "activeGame"
                            )
                          })}
                          onChange={(e) => {
                            this.setState({ in_game_name: e.target.value });
                          }}
                          value={this.state.in_game_name}
                        />
                      </div>
                      <div className="dobns_error_div">
                        <div
                          id="dobns_error_div_game_name"
                          style={{ display: "none" }}
                        >
                          {t('success_Please_enter_Gamerji_username')}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.tag_in_game_name ? (
                    <>
                      {/* { console.log(">>>verification>>>>",this.state.tag_email_verified, this.state.tag_verify_code)} */}
                      <button
                        className="dobns_button cursor"
                        onClick={() => this.onClickSubmit()}
                      >
                        {t('basic_profile.submit')}{" "}
                        <img
                          src={RightArrow}
                          alt=""
                          className="mCS_img_loaded"
                        />
                      </button>
                    </>
                  ) : (
                    (this.state.tag_email ||
                      this.state.tag_dob ||
                      // this.state.tag_state ||
                      this.state.tag_phone) && (
                      <>
                        {/* {console.log(">>>verification>>>>", this.state.tag_email_verified, this.state.tag_phone_verified)} */}
                        <button
                          className="dobns_button cursor"
                          onClick={() => this.onClickSubmit()}
                          disabled={
                            !this.state.tag_email_verified ||
                            !this.state.tag_phone_verified
                          }
                        >
                          {t('basic_profile.submit')}{" "}
                          <img
                            src={RightArrow}
                            alt=""
                            className="mCS_img_loaded"
                          />
                        </button>
                      </>
                    )
                  )}
                </div>
              </div>
              {
                !JSON.parse(localStorage.getItem("premiumUser")) &&

                <Advertisement
                  screen={
                    localStorage.getItem("apk_screens_list") === null
                      ? { code: "no_results" }
                      : JSON.parse(localStorage.getItem("apk_screens_list"))
                        .dobAndStateValidation
                  }
                  screen_div_class_name=".all_sub_screens_div_related_to_ads"
                  screen_type="sub"
                />
              }
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default withTranslation()(DobNStates);
// export default DobNStates;
