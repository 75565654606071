import React from "react";
import "react-intl-tel-input/dist/main.css";
import { Link } from "react-router-dom";
import { routehelp } from "../config/routehelp";

import Previous from "../assets/images/previous.svg";
import Invitation from "../assets/images/invitation.png";
import LeaderboardSVG from "../assets/images/Page-1.svg";
import Friend from "../assets/images/friend.svg";
import DailyLoginRewardsSVG from "../assets/images/reward (1).svg";
import SmartPhone from "../assets/images/smartphone.svg";
import VDOPlayer from "../assets/images/VDO player.svg";
import FilledOutline from "../assets/images/Filled outline.svg";
import FilledOutline2 from "../assets/images/filled outline2.svg";
import LegalDocument from "../assets/images/legal-document.svg";
import AllGames from "../assets/images/all-games.svg";
import RightArrow from "../assets/images/right-arrow.svg";
import Languages from "../assets/images/languages.png";
import Premium from "../assets/images/premium.png";

import JoinViaInviteCode from "./JoinViaInviteCode";
import ApplyPromoCode from "./ApplyPromoCode";
import ChooseYourFavouriteGame from "./ChooseYourFavouriteGame";
import LanguagePopup from "./LanguagePopup";
import Advertisement from "../component/advertisement";
import { TimeScale } from "chart.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withTranslation, useTranslation } from "react-i18next";
import { constants } from "../constants/constants";

import Cookies from "js-cookie";
import i18next from "i18next";
import { eventTracking } from "../firebaseAnalytics";
import { events } from "../utils/appEvents";
class More extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tag_join_via_invite_code: false,
      tag_apply_promo_code: false,
      tag_choose_your_favourite_game: false,
      tag_select_language: false,
    };

    this.onLogout = this.onLogout.bind(this);
    this.leaderboardClick = this.leaderboardClick.bind(this);
    this.onClickCancelIconJVIC = this.onClickCancelIconJVIC.bind(this);
    this.onClickCancelIconAPC = this.onClickCancelIconAPC.bind(this);
    this.onClickCancelIconCYFG = this.onClickCancelIconCYFG.bind(this);
    this.onClickCancelIconSL = this.onClickCancelIconSL.bind(this);
  }

  componentDidMount() {
    // this.getListofDailyRewardsApiCall();
  }

  onLogout() {
    i18next.changeLanguage("EN");

    localStorage.removeItem('gamerjiLogindetails');
    localStorage.removeItem(constants.profileLite);
    localStorage.removeItem(constants.profileBasics);
    localStorage.removeItem(constants.profileFavGames);
    localStorage.removeItem('gamerjiToken');
    localStorage.removeItem('activeGame');
    localStorage.removeItem('currentPage');
    localStorage.removeItem('joinTounament');
    localStorage.removeItem('tournamentId');
    localStorage.removeItem('activeGameId');
    localStorage.removeItem('tournamentImgs');
    localStorage.removeItem('currentCoin');
    localStorage.removeItem('userCountryObj');
    localStorage.removeItem('confirmationResult');
    localStorage.removeItem('rzp_device_id');
    localStorage.removeItem('currentOTPtype');
    // localStorage.removeItem('languageData');
    localStorage.removeItem('loginData');
    localStorage.removeItem('userInGameNames');
    localStorage.removeItem('activeGameType');
    localStorage.removeItem('gamerjiUserInfo');
    localStorage.removeItem('apk_screens_list');
    localStorage.removeItem('countryId');
    localStorage.removeItem('scrimData');
    localStorage.removeItem('players_data');



    localStorage.clear();

    localStorage.setItem("i18nextLng", "EN");
    Cookies.set("i18nextLng", "EN");

    window.history.replaceState(null, '', '/');
    window.location.href = '/';
  }

  leaderboardClick(value) {
    window.location.href = value;
  }

  onClickCancelIconJVIC() {
    this.setState({ tag_join_via_invite_code: false, })
  }

  onClickCancelIconAPC(status) {
    const { t} = this.props;
    if (!status) {
      toast.success(t('success_Promo_code_applied_successfully'), {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    this.setState({ tag_apply_promo_code: false, })
  }

  onClickCancelIconCYFG() {
    this.setState({ tag_choose_your_favourite_game: false, })
  }

  onClickCancelIconSL() {
    this.setState({ tag_select_language: false, })
  }

  render() {
		const { t } = this.props;

    return (
      <div className="inner-wrap">
        <ToastContainer />

        {
          this.state.tag_join_via_invite_code === true ?
            <JoinViaInviteCode onClickCancelIconJVIC={this.onClickCancelIconJVIC} />
            : ''
        }
        {
          this.state.tag_apply_promo_code === true ?
            <ApplyPromoCode onClickCancelIconAPC={this.onClickCancelIconAPC} />
            : ''
        }
        {
          this.state.tag_choose_your_favourite_game === true ?
            <ChooseYourFavouriteGame onClickCancelIconCYFG={this.onClickCancelIconCYFG} />
            : ''
        }
        {
          this.state.tag_select_language === true ?
            <LanguagePopup onClickCancelIconSL={this.onClickCancelIconSL} />
            : ''
        }
        <div className="header">
          <div className="back-btn">
            {/* <Link to="/">
              <img src={Previous} alt="" />
            </Link> */}
          </div>
          <h2>{t('header.more')}</h2>
          <div className="wallet-btn">
            {/* <Link to="">
              <img src={Wallet} alt="" />
              Wallet
            </Link> */}
          </div>
        </div>
        <div className="body bottom-btn-body" style={{ paddingTop: '20px',paddingBottom:'0' }}>
          <div className="all_main_screens_div_related_to_ads">
            <div className="recent_transaction_items more_tab_items">
              <div className="accordion" id="accordionExample">
               {/* Gamerji Premium */}
               <div className="item" >
                  <Link
                    className="accordion-header linka"
                    data-toggle="collapse"
                    data-target="#a_1"
                    aria-expanded="false"
                    style={{background:'#1C1C1C', color:'#C5A260'}}
                    to={routehelp.premiumSubscription}
                    onClick={() => {
                      // this.leaderboardClick(routehelp.premiumSubscription);
                      eventTracking(events.premium_menu);}}
                  >
                    {/* <Link to={routehelp.premiumSubscription}> */}
                    <img src={Premium} alt=""  style={{width:'28px', height:'24px'}}/>
                    <span style={{lineHeight:'1.5px'}}>{t('premium.gamerji_premium')}</span>
                    <span className="icon arrow-deg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21.493"
                        height="18.154"
                        viewBox="0 0 21.493 18.154"
                      >
                        <g
                          id="ico"
                          transform="translate(21.493 18.151) rotate(-180)"
                        >
                          <path
                            id="union"
                            d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z"
                            transform="translate(0 0)"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                    </span>
                    {/* </Link> */}
                  </Link>
                </div>
                {/* Join Via Invite Code */}
                <div className="item">
                  <div
                    className="accordion-header"
                    data-toggle="collapse"
                    data-target="#a_1"
                    aria-expanded="false"
                    onClick={() => { this.setState({ tag_join_via_invite_code: true, }); eventTracking(events.join_via_invite_code); }}
                  >
                    <img src={Invitation} alt="" />
                    {t('right_hand_drop_down.join_via_invite_code')}
                    <span className="icon arrow-deg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21.493"
                        height="18.154"
                        viewBox="0 0 21.493 18.154"
                      >
                        <g
                          id="ico"
                          transform="translate(21.493 18.151) rotate(-180)"
                        >
                          <path
                            id="union"
                            d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z"
                            transform="translate(0 0)"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                    </span>
                  </div>
                </div>

                {/* Leaderboard */}
                <div className="item">
                  <Link
                    className="accordion-header linka"
                    data-toggle="collapse"
                    data-target="#a_1"
                    aria-expanded="false"
                    to={routehelp.leaderboard}
                    onClick={() => {
                      // this.leaderboardClick(routehelp.leaderboard);
                      eventTracking(events.leaderboard_menu);}}
                  >
                    <img src={LeaderboardSVG} alt="" />
                    {t('sidebar.leaderboard')}
                    <span className="icon arrow-deg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21.493"
                        height="18.154"
                        viewBox="0 0 21.493 18.154"
                      >
                        <g
                          id="ico"
                          transform="translate(21.493 18.151) rotate(-180)"
                        >
                          <path
                            id="union"
                            d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z"
                            transform="translate(0 0)"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                    </span>
                  </Link>
                </div>

  {/* not included in project */}
                {/* Refer A Friend */}
              
                {/* <div className="item">
                  <div
                    className="accordion-header"
                    data-toggle="collapse"
                    data-target="#a_1"
                    aria-expanded="false"
                    onClick={() => this.leaderboardClick(routehelp.referAFriend)}
                  >
                    <img src={Friend} alt="" />
                   {t('right_hand_drop_down.refer_a_friend')}
                    <span className="icon arrow-deg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21.493"
                        height="18.154"
                        viewBox="0 0 21.493 18.154"
                      >
                        <g
                          id="ico"
                          transform="translate(21.493 18.151) rotate(-180)"
                        >
                          <path
                            id="union"
                            d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z"
                            transform="translate(0 0)"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                    </span>
                  </div>
                </div> */}

                {/* Daily Login Rewards */}
                {/* <div className="item">
                  <div
                    className="accordion-header"
                    data-toggle="collapse"
                    data-target="#a_1"
                    aria-expanded="false"
                    onClick={() => this.leaderboardClick(routehelp.dailyloginrewards)}
                  >
                    <img src={DailyLoginRewardsSVG} alt="" />
                    {t('right_hand_drop_down.daily_login_rewards')}
                    <span className="icon arrow-deg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21.493"
                        height="18.154"
                        viewBox="0 0 21.493 18.154"
                      >
                        <g
                          id="ico"
                          transform="translate(21.493 18.151) rotate(-180)"
                        >
                          <path
                            id="union"
                            d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z"
                            transform="translate(0 0)"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                    </span>
                  </div>
                </div> */}

                {/* Apply Promo Code */}
                <div className="item">
                  <div
                    className="accordion-header"
                    data-toggle="collapse"
                    data-target="#a_1"
                    aria-expanded="false"
                    onClick={() => {this.setState({ tag_apply_promo_code: true, }); eventTracking(events.apply_promo_code);}}
                  >
                    <img src={SmartPhone} alt="" />
                    {t('right_hand_drop_down.apply_promo_code')}
                    <span className="icon arrow-deg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21.493"
                        height="18.154"
                        viewBox="0 0 21.493 18.154"
                      >
                        <g
                          id="ico"
                          transform="translate(21.493 18.151) rotate(-180)"
                        >
                          <path
                            id="union"
                            d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z"
                            transform="translate(0 0)"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                    </span>
                  </div>
                </div>

                {/* How To Play - How To Play onClick 
                this.leaderboardClick(routehelp.howToPlay) - img VDOPlayer */}

                {/* GamerJi Points */}
                <div className="item">
                  <Link
                    className="accordion-header linka"
                    data-toggle="collapse"
                    data-target="#a_1"
                    aria-expanded="false"
                    to={routehelp.gamerjipoints}
                    onClick={() => {
                      // this.leaderboardClick(routehelp.gamerjipoints); 
                      eventTracking(events.easypaisa_points);}}
                  >
                    <img src={FilledOutline} alt="" />
                    {t('right_hand_drop_down.gamerji_points')}
                    <span className="icon arrow-deg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21.493"
                        height="18.154"
                        viewBox="0 0 21.493 18.154"
                      >
                        <g
                          id="ico"
                          transform="translate(21.493 18.151) rotate(-180)"
                        >
                          <path
                            id="union"
                            d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z"
                            transform="translate(0 0)"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                    </span>
                  </Link>


                  <div
                    id="a_3"
                    className="txt mb-0 collapse"
                    data-parent="#accordionExample"
                  >
                    <Link to="" className="text-dark">
                      <b>{t('right_hand_drop_down.terms_of_use')}</b>
                    </Link>
                    <hr />
                    <Link to="" className="text-dark">
                      <b>{t('right_hand_drop_down.privacy_policy')}</b>
                    </Link>
                  </div>
                </div>

                {/* Customer Care */}
                <div className="item">
                  <Link
                    className="accordion-header linka"
                    data-toggle="collapse"
                    data-target="#a_6"
                    aria-expanded="false"
                    to={routehelp.customercare}
                    onClick={() => {
                      // this.leaderboardClick(routehelp.customercare);
                      eventTracking(events.crm_menu);
                    }}
                  >
                    <img src={FilledOutline2} alt="" />
                    {t('sidebar.customer_care')}
                    <span className="icon arrow-deg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21.493"
                        height="18.154"
                        viewBox="0 0 21.493 18.154"
                      >
                        <g
                          id="ico"
                          transform="translate(21.493 18.151) rotate(-180)"
                        >
                          <path
                            id="union"
                            d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z"
                            transform="translate(0 0)"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                    </span>
                  </Link>
                </div>

                {/* Legality */}
                <div className="item">
                  <div
                    className="accordion-header"
                    data-toggle="collapse"
                    data-target="#a_7"
                    aria-expanded="false"
                    onClick={()=>{ eventTracking(events.legality);}}
                  >
                    <img src={LegalDocument} alt="" />
                    {t('right_hand_drop_down.legality')}
                    <span className="icon arrow-deg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21.493"
                        height="18.154"
                        viewBox="0 0 21.493 18.154"
                      >
                        <g
                          id="ico"
                          transform="translate(21.493 18.151) rotate(-180)"
                        >
                          <path
                            id="union"
                            d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z"
                            transform="translate(0 0)"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                    </span>
                  </div>
                  <div
                    id="a_7"
                    className="txt mb-0 collapse"
                    data-parent="#accordionExample"
                  >
                    <Link to={routehelp.terms} className="text-dark">
                      <b>{t('right_hand_drop_down.terms_of_use')}</b>
                    </Link>
                    {/* <hr />
                    <Link to={routehelp.privacy} className="text-dark">
                      <b>{t('right_hand_drop_down.privacy_policy')}</b>
                    </Link> */}
                  </div>
                </div>

{/* not included in project */}
                {/* Choose Your Favourite Game */}
                {/* <div className="item">
                  <div
                    className="accordion-header"
                    data-toggle="collapse"
                    data-target="#a_1"
                    aria-expanded="false"
                    onClick={() => this.setState({ tag_choose_your_favourite_game: true, })}
                  >
                    <img src={AllGames} alt="" />
                    {t('right_hand_drop_down.favorite_game')}
                    <span className="icon arrow-deg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21.493"
                        height="18.154"
                        viewBox="0 0 21.493 18.154"
                      >
                        <g
                          id="ico"
                          transform="translate(21.493 18.151) rotate(-180)"
                        >
                          <path
                            id="union"
                            d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z"
                            transform="translate(0 0)"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                    </span>
                  </div>
                </div> */}

                {/* Select Language */}
                {/* <div className="item">
                  <div
                    className="accordion-header"
                    data-toggle="collapse"
                    data-target="#a_1"
                    aria-expanded="false"
                    onClick={() => this.setState({ tag_select_language: true, })}
                  >
                    <img src={Languages} alt="" />
                   {t('right_hand_drop_down.change_language')}
                    <span className="icon arrow-deg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21.493"
                        height="18.154"
                        viewBox="0 0 21.493 18.154"
                      >
                        <g
                          id="ico"
                          transform="translate(21.493 18.151) rotate(-180)"
                        >
                          <path
                            id="union"
                            d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z"
                            transform="translate(0 0)"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                    </span>
                  </div>
                </div> */}

                {/* My Videos */}
                {/* <div className="item">
                  <div
                    className="accordion-header"
                    data-toggle="collapse"
                    data-target="#a_1"
                    aria-expanded="false"
                    onClick={() => this.leaderboardClick(routehelp.myVideos)}
                  >
                    <img src={DailyLoginRewardsSVG} alt="" />
                    My Videos
                    <span className="icon arrow-deg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21.493"
                        height="18.154"
                        viewBox="0 0 21.493 18.154"
                      >
                        <g
                          id="ico"
                          transform="translate(21.493 18.151) rotate(-180)"
                        >
                          <path
                            id="union"
                            d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z"
                            transform="translate(0 0)"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                    </span>
                  </div>
                </div> */}

                {/* LOGOUT */}
                <div className="item">
                  <button className="btn btn-dark main-btn filter_popup_toggle" style={{marginTop:'13rem'}} onClick={(e) => {this.onLogout(); eventTracking(events.logout);}} >
                  {t('right_hand_drop_down.logout_cta')} <img src={RightArrow} alt="" className="mCS_img_loaded" />
                  </button> 

                  <div
                    id="a_9"
                    className="txt mb-0 collapse"
                    data-parent="#accordionExample"
                  >
                    <Link to="" className="text-dark">
                      <b>{t('right_hand_drop_down.terms_of_use')}</b>
                    </Link>
                    <hr />
                    <Link to="" className="text-dark">
                      <b>{t('right_hand_drop_down.privacy_policy')}</b>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
             !JSON.parse(localStorage.getItem("premiumUser")) && 
          
          <Advertisement
            screen={
              localStorage.getItem('apk_screens_list') === null ?
                { code: 'no_results' }
                :
                JSON.parse(localStorage.getItem('apk_screens_list')).more
            }
            screen_div_class_name='.all_main_screens_div_related_to_ads'
            screen_type='main'
          />
  }
        </div>
        {/* <div className="bottom_btn">
        
        </div> */}
      </div>
    );
  }

}
// export default More;
export default  withTranslation()(More);

{/* 
  <div className="item">
    <div
      className="accordion-header"
      data-toggle="collapse"
      data-target="#a_2"
      aria-expanded="false"
    >
      <img src={Friend} alt="" />
      Refer a Friend
      <span className="icon arrow-deg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21.493"
          height="18.154"
          viewBox="0 0 21.493 18.154"
        >
          <g
            id="ico"
            transform="translate(21.493 18.151) rotate(-180)"
          >
            <path
              id="union"
              d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z"
              transform="translate(0 0)"
              fill="currentColor"
            />
          </g>
        </svg>
      </span>
    </div>
    <div
      id="a_2"
      className="txt mb-0 collapse"
      data-parent="#accordionExample"
    >
      <Link to="" className="text-dark">
        <b>Terms of Use</b>
      </Link>
      <hr />
      <Link to="" className="text-dark">
        <b>Privacy Policy</b>
      </Link>
    </div>
  </div> 
  <div className="item">
    <div
      className="accordion-header"
      data-toggle="collapse"
      data-target="#a_8"
      aria-expanded="false"
    >
      <img src={FillOutline} alt="" />
      Change Password
      <span className="icon arrow-deg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21.493"
          height="18.154"
          viewBox="0 0 21.493 18.154"
        >
          <g
            id="ico"
            transform="translate(21.493 18.151) rotate(-180)"
          >
            <path
              id="union"
              d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z"
              transform="translate(0 0)"
              fill="currentColor"
            />
          </g>
        </svg>
      </span>
    </div>
    <div
      id="a_8"
      className="txt mb-0 collapse"
      data-parent="#accordionExample"
    >
      <Link to="" className="text-dark">
        <b>Terms of Use</b>
      </Link>
      <hr />
      <Link to="" className="text-dark">
        <b>Privacy Policy</b>
      </Link>
    </div>
  </div>
*/}
