import React, { Fragment, useEffect, useState } from "react";
import {
  
  requestLevelList,
  requestGameStats,
  getCollegeList,
  getUpdateProfile,
  getUserProfile,
  getUserBasicsDetails,
  getGamePlayed,
  geProfileDetails,
  getProfileDetails
} from "../apis";
import url from "../constants/url";
import colors from "../constants/colors";
import Dollar from "../assets/images/upload-card.svg";
import Coin from "../assets/images/coin.png";
import Rupee from "../assets/images/rupee.svg";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import Close from "../assets/images/close.svg";
import { kFormatter } from "../component/common";
import Progress from "react-progressbar";
import Loader from "../component/loader";
import { routehelp } from "../config/routehelp";

import SearchIcon from "../assets/images/search-rounded.png";
import ShareProfileIcon from "../assets/images/share-profile-icon.png";
import EditProfileIcon from "../assets/images/edit-profile-icon.png";
import InsightsIcon from "../assets/images/insights-icon.png";

import SchoolIcon from "../assets/images/school.png";
import RankIcon from "../assets/images/rank-collegiate.png";
import TeamIcon from "../assets/images/members-collegiate.png";
import PointIcon from "../assets/images/point-collegiate.png";
import RightArrowIcon from "../assets/images/right_arrow.png";

import Advertisement from "../component/advertisement";
import RightArrow from "../assets/images/right-arrow.svg";
import PremiumClose from "../assets/images/cancel_premium.png"
import PremiumLogo from '../assets/images/premium.png'
import Logo from "../assets/images/easypaisa_logo.png"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { CollegePopup } from "../component/CollegePopup";
import Error from "../component/error";
import { RWebShare } from "react-web-share";
import  PremiumLogoBlack from '../assets/images/GoldPremium.png'
import { useTranslation, Trans } from "react-i18next";
import Cookies from "js-cookie";
import { eventTracking } from "../firebaseAnalytics";
import { events } from "../utils/appEvents";
import { useProfile } from "../component/ProfileComponent/ProfileContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function Profile(props) {
  const [allData, setAllData] = useState({});
  const [userBasicDetails, setUserBasicDetails] = useState({});
  const [levelsList, setLevelsList] = useState("");
  const [infoTitle, setInfoTitle] = useState("");
  const [infoDesc, setInfoDesc] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const [gameStatsList, setGameStatsList] = useState([]);
  const [levelProgressValue, setLevelProgressValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showmSubModal, setShowSubModal] = useState(false);
  const [collegeList, setCollegeList] = useState(null);
  const [collegeToSubmit, setSelectedCollege] = useState(null);
  const [game, setGame] = useState("Select College");
  const [error, setError] = useState("");
  const {premiumUserDetails} = useProfile()
  const [premiumUser, setPremiumUser] = useState(false)
  const [showPremiumPopup, setShowPremiumPopup] =useState(false)
  const {t} = useTranslation()
  const currentLang = Cookies.get('i18next') || 'EN';
  useEffect(()=>{
    setShowPremiumPopup(!premiumUserDetails?.isPremium)
    setPremiumUser(premiumUserDetails?.isPremium)

    EventTracking(); 
  },[]);

  const EventTracking=()=>{
    eventTracking(events.my_profile);
  }
  const SubCollegeModal = (e) => {
    setShowSubModal((prev) => !prev);
  };

  // console.log(collegeList);

  const handleGame = async (item) => {
    setGame(item.name);
    console.log(item)
    setSelectedCollege(item)

  };
  const submitCollege = async () => {



    if (collegeToSubmit) {
      let payload = { 'college': collegeToSubmit._id };
      let response = await getUpdateProfile(payload)
      if (response.item) {
        window.location.reload(false);

      }

    } else {
      setError(t('error_please_select_collage'))
    }

  };
  const options = {
    responsive: true,
    fill: true,
    scales: {
      y: {
        beginAtZero: true,
        suggestedMin: 0,
        suggestedMax: 5,
        // min:1,
        // max:5,
      },
    },
    plugins: {
      legend: {
        position: false,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };
  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];
  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: [200, 500, 300, 400, 700, 100, 500],
        borderColor: "rgb(255, 198, 9)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
  useEffect(() => {
    fetchData();
    // fetchLevelData();
    return ()=>{
      setAllData({})
      setCollegeList(null)
      setLevelsList("")
      setGameStatsList([])
    }
  }, []);


  const getTotalCoin = (wallet) => {
    let index = wallet.findIndex(obj => obj.currencyData?.code == 'EZP')

    if (index > -1) {
      return wallet[index].winningAmount ? wallet[index].winningAmount : 0
    }
    return 0
  }
  const getTotalDiamond = (wallet) => {
    let index = wallet.findIndex(obj => obj.currencyData?.code == 'EZPD')

    if (index > -1) {
      return wallet[index].winningAmount ? wallet[index].winningAmount : 0
    }
    return 0
  }

  const fetchData = async () => {
    setLoading(true);
    //let res = await getUserProfile();
    let res = await getProfileDetails();

    if (res) {
      setAllData(res);
      // localStorage.setItem('currentCoin', getTotalCoin(res?.item?.wallet))
      // localStorage.setItem('currentDiamond', getTotalDiamond(res?.item?.wallet));
     // no need to call
      // let profileBasicResposne = await getUserBasicsDetails();
      // if (profileBasicResposne.level) {
      // setUserBasicDetails(profileBasicResposne)

      //   // let currentLevelPoints = res.item.level?.level?.endPoint -  (res.item.level?.level?.startPoint - 1);

      //   // let userPointsAsPerLevel =  res.item.level?.points -  (res.item.level?.level?.startPoint - 1)
      //   let currentLevelPoints =
      //   profileBasicResposne.level?.level?.endPoint -
      //     (profileBasicResposne.level?.level?.startPoint - 1);

      //   let userPointsAsPerLevel =
      //   profileBasicResposne.level?.points - (profileBasicResposne.level?.level?.startPoint - 1);

      //   // console.log("currentLevelPoints===>", currentLevelPoints)
      //   // console.log("userPointsAsPerLevel===>", userPointsAsPerLevel)
      //   // console.log("res.item.level?.points===>", res.item.level?.points)

      //   let lpVal = (userPointsAsPerLevel * 100) / currentLevelPoints;
      //   // console.log("lpVal===>", lpVal)
      //   setLevelProgressValue(lpVal);
      // }
    
        let currentLevelPoints =
        res?.item.level?.endPoint -
          (res?.item.level?.startPoint - 1);

        let userPointsAsPerLevel =
        res?.item.totalPoint - (res?.item.level?.startPoint - 1);

       
      // console.log(res?.item.level?.endPoint,res?.item.level?.startPoint,res?.item.level?.endPoint -
      //   (res?.item.level?.startPoint - 1), userPointsAsPerLevel, userPointsAsPerLevel)

        let lpVal = (userPointsAsPerLevel * 100) / currentLevelPoints  || 0;
        // console.log("lpVal===>", lpVal)
        setLevelProgressValue(lpVal);
        getStatisticsList()
      if (res?.item?.gameNames && res?.item?.gameNames.length > 0) {
        fetchGameStats(res?.item?.gameNames);
      }
      // college is not included
      // call college list api
    //   let collegeList = await getCollegeList({
    //     "sort": "desc",
    //     "skip": 0,
    //     "limit": 20,
    //     "sortBy": "createdAt",
     
    //     // "q": "Delhi University"
    //   });
    //   if (collegeList) {
    //     // console.log("College: ", collegeList.list);
    //     setCollegeList(collegeList?.list);
    //   }
    //   setLoading(false);
    setLoading(false);

    }
  };

  const fetchLevelData = async (title, desc) => {
    let payload = {
      skip: 0,
      limit: 100,
      sort: "asc",
      sortBy: "num",
    };
    let res = await requestLevelList(payload);
    if (res && res.list) {
      setLevelsList(res.list);
      setShowInfo(true);
      setInfoTitle(title);
      setInfoDesc(desc);
    }
  };

  const fetchGameStats = async (gameNames) => {
    setLoading(true);
    let arrGameStats = [];
    let games = [];
    let gameNamesArr = [];
    if (gameNames && gameNames.length > 0) {
      gameNames.forEach(async function (element) {
        if (element.uniqueIGN != "" && element.uniqueIGN != undefined) {
          games.push(element.game);
          gameNamesArr[element.game] = element.uniqueIGN;
        }
      });
      let res = await requestGameStats({ games: gameNames, appType: "webapp" });
      if (res && res.list) {
        var gameDataArr = [];
        res.list.forEach(async function (element, index) {
          var gameDataDateArr = [];
          var gameDataPlayedArr = [];
          element.stateatistic.forEach(function (element1) {
            var d = new Date(element1.date);
            gameDataDateArr.push(element1.date);
            gameDataPlayedArr.push(element1.played);
          });
          gameDataArr.push({
            avgRates: element.avgRates,
            game: element.game,
            played: element.played,
            uniqueIGN: gameNamesArr[element.game._id.toString()],
            data: {
              labels: gameDataDateArr,
              datasets: [
                {
                  label: "Played " + element.played,
                  data: gameDataPlayedArr,
                  borderColor: "rgb(255, 198, 9)",
                  backgroundColor: "rgb(255, 198, 9)",
                },
              ],
            },
          });
        });
        setGameStatsList(gameDataArr);
      }
      setLoading(false);

    }
  };

  const getStatisticsList = async () => {
    console.log("get game played", allData?.item)
    if(premiumUserDetails?.userID) {
      setLoading(true);
      try {
        let data = {
          user_id: premiumUserDetails?.userID
        }
        let gameList = await getGamePlayed(data);
        console.log("game list", gameList)
        let payload = {
          games: gameList?.data?.data,
          appType: "webapp",
          user: allData?.item?.user_id
        };
        fetchGameStats(gameList?.data);
        // const res = await requestGameStats(payload);
        // setGameStatsList(res?.data?.list);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const onClickInfo = (title, desc) => {
    fetchLevelData(title, desc)
  
  };
  const getLevelProgressValue = (level) => {
    let currentLevelPoints =
      level?.level?.endPoint - level?.level?.startPoint - 1;
    let userPointsAsPerLevel = level?.points - level?.level?.startPoint - 1;

    return userPointsAsPerLevel / currentLevelPoints;
  };
  const topLinkClick = (value) => {
    window.location.href = value;
  };
  const onClickCollegeViewDetail = (value) => {
    window.location.href = value;
  };
  const navigate = useNavigate()
  // console.log("gameStatsList==>", gameStatsList);
  const insightsHandler = (id)=>{
    // if(premiumUser)
    navigate(`/profile-insights/${id}`)
    // else{
    //   setShowPremiumPopup(true)
    // }
  }

  return (
   
    <div
      className="inner-wrap custom_scroll_leader"
      // style={{   overflow: "auto" }}
      id="account_main"
    >
     
      <div
        className="profile_main mCustomScrollbar _mCS_1"
        id={showInfo === true ? "popup_show" : ""}
      >
        <div className="all_main_screens_div_related_to_ads">
          {loading ? <Loader /> : ""}
          {/* Navigation Bar */}
          <div className="profile_top row">
            <div className="col-10"></div>
            <div className="col-1 p-0">
              {/* Search */}
              {/* <Link
                to="#"
                style={{
                  // height: 50,
                  // width: 40,
                  display:'flex',
                  flexDirection:'column',
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => topLinkClick(routehelp.searchuser)}
              >
                <img style={{ height: 24, width: 24 }} src={SearchIcon} alt="search"/>
                <p style={{ marginTop: 3, color: colors.black, fontSize: 10 }}>
                  {t('leaderboard.search')}
                </p>
              </Link> */}
            </div>
            {/* <div className="col-1 p-0">
              <RWebShare
                data={{
                  text: `${t('Profile.join_text', { name: "easypaisa" })}`,
                  url: `${url.siteUrl}user-profile/${allData?.item?._id}`

                  ,
                  title:  `${t('Profile.join_me', { name: "easypaisa" })}`,
                }}
                onClick={() => {console.info("share successful!");
                  
                  eventTracking(events.share_profile);

                }}
              >
                <Link
                to="#"
                style={{
                  height: 50,
                  width: 40,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img style={{ height: 24, width: 24 }} src={ShareProfileIcon} />
                <p style={{ marginTop: 3, color: colors.black, fontSize: 10 }}>
                  {t('Profile.share')}
                </p>
              </Link>
                
              </RWebShare>
             
            </div> */}
            <div className="col-1 p-0">
              {/* Edit */}
              <Link
                to="/profile-edit"
                style={{
                  // height: 50,
                  // width: 40,
                  display:'flex',
                  flexDirection:'column',
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={()=>{
                  eventTracking(events.profile_edit);
                }}
              >
                <img style={{ height: 24, width: 24 }} src={EditProfileIcon}  alt="Edit"/>
                <p style={{ marginTop: 3, color: colors.black, fontSize: 10, textAlign:'center' }}>
                {t('Profile.edit')}
                </p>
              </Link>
            </div>
          </div>
          <div className="profile_first row">
            <div className="col-6 avatar_detail p-0">
              
              <div
                className="col p-0 profile-ff"
               
              >
                <div className="d-flex align-items-center profile-ml-20" >
               {
                premiumUser && <div  style={{height:'28px', width:'25px'}}>
                <img style={{width:'28px', height:'25px', marginLeft:'20px',marginRight:'20px'}} src={PremiumLogoBlack} alt="premiu"/>{" "}
          
                </div>
               }
                  
                             <h4 className="f-20" style={currentLang?.toLowerCase() == "ar" ? {color:'#fff', marginRight:'30px', marginTop:'5px'} : {color:'#fff', marginLeft:'30px', marginTop:'5px'}}>
                    {allData?.item?.gamerjiName === undefined ||
                    allData?.item?.gamerjiName === null ? (
                      ""
                    ) : allData?.item?.gamerjiName.length > 10 ? (
                      <marquee>{allData?.item?.gamerjiName}</marquee>
                    ) : (
                      allData?.item?.gamerjiName
                    )}
                  </h4>
                </div>
                 
                {/* <div className="row m-0 profile_gamerji_name_row" > */}
                  
                  {/* <div className="profile_gamerji_name_right_triangle"></div>
                  <div className="profile_gamerji_name_right_upper_triangle"></div> */}
                {/* </div> */}
                <Link to="/friends" style={{color:'#fff'}}>
                  <div className="row m-0 profile_follower_followings_row">
                 
                    <div className="col-5 d-flex flex-column align-items-center justify-content-center">
                      <div className="profile_followers_data">
                        {allData?.item?.followers}
                      </div>
                      <div className="profile_followers_title">{t('Profile.followers')}</div>
                    </div>
                    <div className="" style={{padding:'0 10px'}}>
                      <div className="profile_follower_followings_divider"></div>
                    </div>
                    <div className="col-5 d-flex flex-column align-items-center justify-content-center">
                      <div className="profile_followings_data">
                        {allData?.item?.followings}
                      </div>
                      <div className="profile_followings_title">{t('Profile.following')}</div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="level_box d-flex flex-column text-center">
                <img
                  src={
                    url.imageUrl +
                    allData?.item?.level?.featuredImage?.default
                  }
                  style={{
                    height: 73,
                    width: 70,
                    resizeMode: "contain",
                    alignSelf: "center",
                    marginTop: -30,
                  }}
                  alt="level"
                />
                <div
                  style={{
                    width: 175,
                    height: 30,
                    justifyContent: "center",
                    marginTop: 10,
                  }}
                >
                  <p
                    style={{
                      alignSelf: "center",
                      color: colors.black,
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    {allData?.item?.level?.name}
                  </p>
                </div>
                <div
                  style={{
                    marginTop: 5,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 156,
                  }}
                >
                  <div className="avalible_wrap">
                    <div className="inner">
                      <span style={{ width: levelProgressValue }}></span>
                    </div>
                    <p className="d-flex justify-content-between">
                      <span>{t('Profile.n_pts', {n: allData?.item?.level?.startPoint})}</span>
                      <a>{t('Profile.n_pts', {n: allData?.item?.level?.endPoint})}</a>
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: 10,
                    width: 166,
                    textAlign: "center",
                    color: colors.black,
                    fontWeight: "bold",
                    fontSize: 12,
                  }}
                >
                  {t('Profile.points')} - {allData?.item?.totalPoint}
                </div>
                <div className="profile_level_box_all_level">
                  <div className="row m-0 profile_level_row">
                    <div className="profile_level_left_upper_triangle"></div>
                    <div className="profile_level_left_triangle"></div>
                    <div className="profile_level_details">
                    {t('Profile.level')} - {allData?.item?.level?.num}
                    </div>
                    <div className="profile_level_right_triangle"></div>
                    <div className="profile_level_right_upper_triangle"></div>
                  </div>
                  {/* <div style={{ width: '65%', backgroundColor: 'var(--dark)', borderRadius: '0 0 20px 20px' }}>
                    <p style={{ alignSelf: 'center', color: colors.white, fontSize: 15, fontWeight: "bold", marginBottom: '0.2rem' }}>
                      Level - {allData?.item?.level?.level?.num}
                    </p>
                  </div> */}
                  <a
                    style={{
                      width: "100%",
                      height: 30,
                      borderRadius: 20,
                      justifyContent: "center",
                      color: colors.white,
                      cursor: "pointer",
                      fontSize: 16,
                      fontWeight: "bold",
                      alignSelf: "center",
                      paddingTop: 0,
                      paddingBottom: 5,
                      paddingLeft: 20,
                      paddingRight: 20,
                    }}
                    onClick={() =>{
                      onClickInfo(
                        t('Profile.all_levels'),
                        "Money deposited by you that you can only be used to buy a coins but cannot be withdrawn."
                      ); eventTracking(events.view_all_levels);
                    }
                      
                    }
                  >
                    {t('Profile.all_levels')}
                  </a>
                </div>
              </div>
            </div>

            <>
              {/* <div className="col-6 avatar_detail d-none">
                <h4 style={{ fontWeight: '700', marginBottom: '30px' }}>{allData?.item?.gamerjiName}</h4>
                <div style={{
                  marginTop: 16, flexDirection: 'row', width: 100, borderRadius: 15, border: "solid 1px #e4b226",
                  textAlign: 'center', alignItems: 'center', fontWeight: '700', padding: '3px 0', marginBottom: '60px'
                }}>
                  <Link to='/friends' className="text-dark coin_hover" style={{ color: colors.black, fontSize: 13 }} >
                    Followers ({allData?.item?.followers})
                  </Link>
                </div>
                <div className="level_box d-flex flex-column text-center   pb-2">
                  <img src={url.imageUrl + allData?.item?.level?.level?.featuredImage?.default} style={{ height: 73, width: 70, resizeMode: 'contain', alignSelf: "center", marginTop: -30 }} />
                  <div style={{ width: 175, height: 30, justifyContent: 'center', marginTop: 10 }}>
                    <p style={{ alignSelf: 'center', color: colors.black, fontSize: 16, fontWeight: "bold", marginBottom: '0.2rem' }}>Level - {allData?.item?.level?.level?.num}</p>
                    <p style={{ alignSelf: 'center', color: colors.black, fontSize: 14, fontWeight: "bold" }} > {allData?.item?.level?.level?.name}</p>

                  </div>
                  <div style={{ marginTop: 35, marginLeft: 10, marginRight: 10, width: 156 }} >

                    <div className="avalible_wrap"><div className="inner"><span style={{ width: levelProgressValue }}></span></div><p className="d-flex justify-content-between"><span>{allData?.item?.level?.level?.startPoint} PTS</span><span>{allData?.item?.level?.level?.endPoint} PTS</span></p></div>


                  </div>



                  <div style={{ marginTop: 10, width: 166, textAlign: 'center', color: colors.black, fontWeight: "bold", fontSize: 12 }} >Points - {allData?.item?.level?.points}</div>


                  <div style={{ marginTop: 10, bottom: 0, width: 175, flexDirection: 'row', justifyContent: 'center' }} >
                    <a href="#" style={{ height: 30, width: 100, borderRadius: 20, justifyContent: 'center', backgroundColor: colors.black, color: colors.white, fontSize: 12, fontWeight: "bold", alignSelf: 'center', paddingTop: 5, paddingBottom: 5, paddingLeft: 20, paddingRight: 20 }} onClick={() => onClickInfo('LEVELS', 'Money deposited by you that you can only be used to buy a coins but cannot be withdrawn.')} >
                      View All
                    </a>
                  </div>
                </div>
              </div> */}
            </>

            <div className="col-6 avatar_img ">
              <img
                src={url.imageUrl + allData?.item?.avatar?.img?.default}
                className="ps_avatar_icon_img"
                alt="Avatar"
              />
              {/* {
                premiumUser ?  <img className="ps_profile_img" src={PremiumLogoBlack} /> : <></>
              } */}
           
            </div>
           
          </div>
          <div
            className="profile_second"
            style={{ height: "calc(100% - 78%)" }}
          >
            <div
              className="list-group center profile-tab "
              id="list-tab"
              role="tablist"
              style={{margin:'0 auto', top:'-25px'}}
              
            >
              <a
                className="list-group-item list-group-item-action active"
                id="list-home-list"
                data-toggle="list"
                href="#list-1"
              >
                {t('Profile.stats')}
              </a>
              {/* <a
                className="list-group-item list-group-item-action"
                id="list-profile-list"
                data-toggle="list"
                href="#list-2"
              >
                {t('Profile.collegiate')}
              </a> */}
            </div>
            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane active" id="list-1">
                {gameStatsList && gameStatsList.length > 0 ? (
                  gameStatsList.map((item, index) => {
                    return (
                      <div
                        className="gap-3"
                        style={{ width: "85%", margin: "0 auto 10px auto" }}
                        key={index}
                      >
                        <div className="row">
                          <div className="col-8 text-align-right">
                            <h5 className="game_name_h5">{item?.game?.name}</h5>
                            <h6>{item?.uniqueIGN}</h6>
                          </div>
                          <div className="col-2">
                            <h5 className="game_name_h5">{t('Profile.rank')}</h5>
                            <h6>{item?.avgRates}</h6>
                          </div>
                          <div className="col-2 game_insights_hover" 
                          onClick={()=> {
                            eventTracking(events.view_insights);
                            if(premiumUser)
                            {insightsHandler(item?.game?._id); }
                            else
                            setShowPremiumPopup(true)
                            }} >
                            {/* <Link
                              to={`/profile-insights/${item?.game?._id}`}
                              className="game_insights_hover"
                              style={{ color: colors.white, fontSize: 13 }}
                            >
                              <img
                                style={{
                                  height: 32,
                                  width: 32,
                                  marginLeft: "14px",
                                }}
                                src={InsightsIcon}
                              />
                              <h6>Insights</h6>
                            </Link> */}
                        
                              <img
                                style={{
                                  height: 32,
                                  width: 32,
                                  marginLeft: "14px",
                                }}
                                src={InsightsIcon}
                              />
                              <h6>{t('Profile.insights')}</h6>
                          
                          </div>
                        </div>
                        <div className="row profile-stats-box">
                          <div className="col-1 p-0">
                            {" "}
                            <div className="chart-left-text">{t('home.played')}</div>
                          </div>
                          <div className="col-11 p-0">
                            {" "}
                            <p>{t('home.played')} : {item?.played}</p>
                            <Line options={options} data={item.data} />
                            <p className="chart-bottom-text">{t('Profile.last_14_days')}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="d-flex justify-content-center" style={{}}>
                    <div
                      style={{
                        marginTop: "10px",
                        padding: "20px",
                        color: "#F92C2C",
                        fontSize: "18px",
                        fontWeight: "1000",
                      }}
                    >
                      {t('Profile.no_stats_available')}
                    </div>
                  </div>
                )}
              </div>
              <div className="tab-pane" id="list-2">
                {
                  userBasicDetails &&
                  "college" in userBasicDetails &&
                  "name" in userBasicDetails?.college ? (
                    <div>
                      <div
                        className="collegiate-main-box d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <div className="collegiate-head-box">
                          <div
                            className="d-flex justify-content-center"
                            style={{
                              padding: 2,
                              height: 50,
                              width: 60,
                              backgroundColor: colors.black,
                              borderRadius: 20,
                              marginTop: -40,
                            }}
                          >
                            <img
                              style={{ height: 40, width: 40 }}
                              src={SchoolIcon}
                            />
                          </div>
                          <h5 style={{ fontWeight: "600", marginTop: "10px" }}>
                            {userBasicDetails?.college?.name}
                          </h5>
                        </div>
                        <div className="collegiate-bottom-box row ">
                          <div
                            className="col-12 row p-0"
                            style={{ marginTop: -30 }}
                          >
                            <div className="col-4 p-0">
                              {/* Search */}
                              <img
                                style={{ height: 50, width: 50 }}
                                src={RankIcon}
                              />
                              <p
                                style={{
                                  marginTop: "12px",
                                  marginBottom: "5px",
                                  color: colors.black,
                                  fontSize: "18px",
                                  lineHeight: "20px",
                                }}
                              >
                                <b>{userBasicDetails?.college?.rank}</b>
                              </p>
                              <p
                                style={{
                                  color: colors.black,
                                  fontSize: "14px",
                                  lineHeight: "6px",
                                }}
                              >
                                {t('Profile.rank')}
                              </p>
                            </div>
                            <div className="col-4 p-0">
                              {/* Share */}
                              <img
                                style={{ height: 50, width: 50 }}
                                src={TeamIcon}
                              />
                              <p
                                style={{
                                  marginTop: "12px",
                                  marginBottom: "5px",
                                  color: colors.black,
                                  fontSize: "18px",
                                  lineHeight: "20px",
                                }}
                              >
                                <b>{userBasicDetails?.college?.members}</b>
                              </p>
                              <p
                                style={{
                                  color: colors.black,
                                  fontSize: "14px",
                                  lineHeight: "6px",
                                }}
                              >
                                {t('Profile.members')}
                              </p>
                            </div>
                            <div className="col-4 p-0">
                              {/* Edit */}
                              <img
                                style={{ height: 50, width: 50 }}
                                src={PointIcon}
                              />
                              <p
                                style={{
                                  marginTop: "12px",
                                  marginBottom: "5px",
                                  color: colors.black,
                                  fontSize: "18px",
                                  lineHeight: "20px",
                                }}
                              >
                                <b>{userBasicDetails?.college?.points}</b>
                              </p>
                              <p
                                style={{
                                  color: colors.black,
                                  fontSize: "14px",
                                  lineHeight: "6px",
                                }}
                              >
                                {t('Profile.points')}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="collegiate-botton"
                          style={{ textAlign: "center" }}
                        >
                          <p
                            style={{
                              display: "-webkit-inline-box",
                              color: "#fff",
                            }}
                            onClick={() =>
                              onClickCollegeViewDetail(
                                routehelp.collegiateDetails
                              )
                            }
                          >
                            <span className="collegiate-botton-text ">
                            {t('Profile.view_details')}
                            </span>
                            <img
                              src={RightArrowIcon}
                              className=" mx-1"
                              style={{ width: "16px" }}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center" style={{}}>
                      <div
                        style={{
                          marginTop: "10px",
                          padding: "20px",
                          color: "#F92C2C",
                          fontSize: "18px",
                          fontWeight: "1000",
                          width: "100%",
                        }}
                      >
                        <div className="college-title">
                          {/* <div>{collegeList}</div> */}
                          <label> {t('Profile.select_your_college')}</label>
                          {showmSubModal && (
                            <CollegePopup
                              onSelectItem={handleGame}
                              collegeList={collegeList}
                            />
                          )}
                          <div
                            className="college-popup   "
                            onClick={SubCollegeModal}
                          >
                            {game}
                           

                            <svg
                              className=""
                              width="25"
                              height="25"
                              viewBox="0 0 45 45"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                id=""
                                d="M34 18.34a2 2 0 0 0-2.82 0L24 25.42l-7.08-7.08a2 2 0 1 0-2.82 2.84l8.48 8.48a2.003 2.003 0 0 0 2.19.438c.243-.101.464-.25.65-.438L34 21.18a2 2 0 0 0 0-2.84z"
                                fill="#D3D3D3"
                              ></path>
                            </svg>
                          </div>
                        </div>
                          {error !== "" && <Error style={{ marginTop: 20 }} error={error} />}
                        
                        <button
                          className="jvic_button red-color"
                            onClick={() => submitCollege()}
                        >
                            {t('Profile.submit')} {" "}
                          <img
                            src={RightArrow}
                            alt=""
                            className="mCS_img_loaded"
                          />
                        </button>
                      </div>
                    </div>
                  )
                  // <div className="profile_coligate_selection_college">
                  //   <div className="profile_coligate_select_college_text">Select your college</div>
                  //   <button className="profile_coligate_select_college_button">
                  //     <div>Select College</div>
                  //     <div>
                  //       <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg">
                  //         <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z">
                  //         </path>
                  //       </svg>
                  //     </div>
                  //   </button>
                  //   <button className="profile_coligate_submit_college_button">
                  //     <div>SUBMIT</div>
                  //     <img className="profile_coligate_submit_college_arrow" src={RightArrowIcon} />
                  //   </button>
                  // </div>
                }
              </div>
            </div>
          </div>
          {showInfo === true && (
            <div
              className="custom-popup rules info_acc custom_scroll show"
              id="contact_popup"
            >
              <div className="popup-head">
                <h3 style={{ color: colors.black, fontWeight: "bold" }}>
                  {infoTitle}
                </h3>
                <button
                  className="close-popup"
                  onClick={() => setShowInfo(false)}
                >
                  <img src={Close} alt="" />
                </button>
              </div>
              <div className="popup-body bg-yellow">
                <p className="mb-3 mx-2 my-2" style={{ height: 400 }}>
                  <table className="table profile_level_table">
                    <thead className="table-thead">
                      <tr>
                        <th
                          style={{
                            fontWeight: "bold",
                            fontSize: 18,
                            color: colors.white,
                          }}
                        >
                          {t('Profile.level')}
                        </th>
                        <th
                          style={{
                            fontWeight: "bold",
                            fontSize: 18,
                            color: colors.white,
                            paddingLeft:'1.5rem'
                          }}
                        >
                          {t('Profile.badge')}
                        </th>
                        <th
                          style={{
                            fontWeight: "bold",
                            fontSize: 18,
                            color: colors.white,
                          }}
                        >
                          {t('Profile.points')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {levelsList !== null
                        ? levelsList?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td className="align-middle">{item?.num}</td>
                                <td className="align-middle">
                                  <img
                                    style={{
                                      marginLeft: 8,
                                      width: 40,
                                      height: 40,
                                      resizeMode: "contain",
                                    }}
                                    src={
                                      url.imageUrl +
                                      item?.featuredImage?.default
                                    }
                                  />{" "}
                                  {item?.name}
                                </td>
                                <td className="align-middle">
                                  {item?.startPoint} {t("tournaments.to")} {item?.endPoint}{" "}
                                </td>
                              </tr>
                            );
                          })
                        : ""}
                    </tbody>
                  </table>
                </p>
              </div>
            </div>
          )}
        </div>

        {showPremiumPopup === true && (
        <div className="tc_popup_bg">
          <div
            className={`custom-popup  confirm custom_scroll join show premium_active rules`}
            id="contact_popup"
          >
            
            <div className={`popup-head  premium_active`}>
              {/* <marquee> */}
              <h3>  </h3>
              {/* </marquee> */}

              <button
                className="close-popup"
                onClick={() => setShowPremiumPopup(false)}
              >
                <img src={ PremiumClose} alt="" />
              </button>
            </div>
            <div className='ps_header' style={{height:'150px'}}>
              <div className='ps_logo'><img src={Logo} alt="Logo" /></div>
              <div className='ps_name'>{t('tournaments.premium')}</div>
            </div>
            <div className="ps_popup_main">
            <div className="ps_popup_p"><p>
            {t('premium.as_a_gamerji_user_you_will_be_able_to_unlock_view_more_insights_regarding_your_gameplay')}</p></div>
           
            <div  className="ps_popup_p">
              <p>{t('premium.premium_users_also_get_access_to_a_host_additional_benefits')}</p>
            </div>
            </div>
            
            <button
                  className={`btn premium_active_btn`}
                  onClick={() => navigate(routehelp.premiumSubscription)}
                  style={{textAlign:'center', width:'80%', margin:'0 auto', marginBottom:'30px'}}
                >
                  {t('Profile.explore_gamerji_premium')}                  
                </button>
          </div>
        </div>
      )}

      {
         !JSON.parse(localStorage.getItem("premiumUser")) && 
      

        <Advertisement
          screen={
            localStorage.getItem("apk_screens_list") === null
              ? { code: "no_results" }
              : JSON.parse(localStorage.getItem("apk_screens_list")).profile
          }
          screen_div_class_name=".all_main_screens_div_related_to_ads"
          screen_type="main"
        />
}
      </div>
    </div>

  );
}

export default Profile;
