import React, { useEffect, useState } from "react";
import Close from "../assets/images/close.svg";
import PremiumClose from "../assets/images/cancel_premium.png";
// import { Link } from "react-router-dom";
import PremiumLogo from "../assets/images/easypaisa_logo.png";
import Logo from "../assets/images/logo.svg";

import Lottie from "reactjs-lottie";
import { getWalletLimit, checkDateTime, onCreateContest } from "../apis";
import {
  formatAMPM,
  getTournamentDateTime,
  getProfiledateFormat,
  checkDobNstateConditions,
} from "../component/common";
import rightArrow from "../assets/images/right-arrow.svg";
import rightArrowBlack from "../assets/images/right-arrow-black.svg";
import successCheck from "../assets/images/success.json";
import pay from "../assets/images/pay.svg";
import url from "../constants/url";
import { routehelp } from "../config/routehelp";
import DobNStates from "../screens/dobnstate";
import SquadRegistration from "../screens/SquadRegistration";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import roller from '../assets/images/rolling.gif'
import Advertisement from "../component/advertisement";
import { constants } from "../constants/constants";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { eventTracking } from "../firebaseAnalytics";
import { events } from "../utils/appEvents";
import { useProfile } from "./ProfileComponent/ProfileContext";

function ContestStructure(props) {
  // DobNState Screen Name
  const dobNstateScreenName = "tournaments-contests";

  const [allData, setAllData] = useState([]);
  const [allWinners, setAllWinners] = useState([]);
  const [walletUsage, setWalletUsageData] = useState(null);
  const [selectedFeaturedData, setselectedFeaturedData] = useState({});

  const [progressWidth, setProgressWidth] = useState(0);
  const [coin, setCoin] = useState(0);

  const [showdobnstate, setShowdobnstate] = useState(false);
  const [showWinners, setShowWinners] = useState(false);
  const [coinStore, setCoinStore] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  // Squad Registartion
  const [selectedPlayerData, setSelectedPlayerData] = useState([]);
  const [total_players, setTotal_players] = useState(0);
  const [extra_players, setExtra_players] = useState(0);
  const [showJoinTeam, setShowJoinTeam] = useState(false);
  const [currentName, setcurrentName] = useState("");
  const [premiumUser, setPremiumUser] = useState(
    JSON.parse(localStorage.getItem("premiumUser"))
  );
  const [showPremiumPopup, setShowPremiumPopup] = useState(false);
  const [isDiamond, setIsDiamond] = useState(false)
  const navigate = useNavigate();
  const [isBusy, setIsBusy] = useState(false)
  const { t } = useTranslation()
  const {walletDetails} = useProfile()
  let timer = null

  useEffect(() => {
    // console.log("props?.allData==>", props?.allData);
    localStorage.setItem("activeGameId", props?.allData?.gameAry?.[0]?._id);
    localStorage.setItem("activeGame", props?.allData?.gameAry?.[0]?.name);
    if (props?.allData) {
      var per =
        (100 * props?.allData?.totalJoinedPlayers) /
        props?.allData?.totalPlayers +
        "%";
      setProgressWidth(per);
      setAllData(props?.allData);
    }
    if( timer) return clearTimeout(timer) 
  }, [props?.allData]);

  const getjoin = async () => {
    // console.log("*****alldata ***********",allData)
    var uniqueI = "";
    localStorage.setItem("joinTounament", JSON.stringify(allData));
    //console.log("***********^^^^^^^^^^^^^^^^^^^^^^^^^**************",allData?.gameAry[0]?._id)
    localStorage.setItem("activeGameId", allData?.gameAry[0]?._id);
    localStorage.setItem("activeGame", allData?.gameAry[0]?.name);
    // let games = JSON.parse(
    //   localStorage.getItem(constants.profileLite)
    // )?.item?.gameNames.find((val) => val?.game === allData?.game?._id);

    localStorage.setItem("tournamentId", allData._id);
    // localStorage.setItem("activeGameId", allData?.game?._id);
    uniqueI = allData?.userInGameName;
    setcurrentName(uniqueI);
    // console.log("allData===>", allData);

    // var gameid =
    //   window.location.href.split("/")[
    //   window.location.href.split("/").length - 1
    //   ];
      eventTracking(events.contest_join_now, {
         EP_GameName:localStorage.getItem("activeGameName"),
          EP_GameType:JSON.parse(localStorage.getItem("activeGameType"))?.name, 
       EP_GameID:localStorage.getItem("activeGameId"), EP_ContestID:allData._id});

    //let data = { contest: allData._id, type: "contest" };
    //console.log("***********^^^^^^^^^^^^^^^^^^^^^^^^^**************",allData?.currency[0]?.inCurrency._id)
    let payload = {
      contest: allData._id,
      type: "contest",
      entryFee: allData.entryFee,
      currencyCode: allData?.currency[0]?.inCurrency._id, // change to inCurrency
    };
    if (premiumUser) {
      setCoinStore(false)
      // const userLoginData = JSON.parse(localStorage.getItem("profileLite"))?.item;
      setCoin(walletDetails?.wallet?.filter((item) => item?.currencyData?._id?.toString() == allData?.currency[0]?.inCurrency?._id?.toString())?.[0]?.winningAmount)
    }
    else {
      let response = await getWalletLimit(payload);
      console.log('Wallet API: ',response?.item?.winningAmount, response?.item?.entryFee);
      if (response) {
        setCoinStore(
          response?.item?.winningAmount >= response?.item?.entryFee ? false : true
        );
        setCoin(response?.item?.winningAmount);
        setWalletUsageData(response?.item);
      }
    }


    setselectedFeaturedData(allData);

    console.log(checkDobNstateConditions(dobNstateScreenName, uniqueI) ,uniqueI )
    if (checkDobNstateConditions(dobNstateScreenName, uniqueI) || !uniqueI) {
      setShowdobnstate(true);
    } else {
      setShowConfirm(true); //
    }
  };

  let findUserName = (gameid) => {
    // console.log("gameid", gameid);
    let gameNames = JSON.parse(localStorage.getItem("userInGameNames"));
    if (gameNames) {
      let index = gameNames.findIndex((obj) => obj.game == gameid);
      if (index > -1) {
        setcurrentName(gameNames[index].userINGameName);
        // return gameNames[index].userINGameName || ""
      } else {
        return "";
      }
    }
  };

  const onJoinTournament = async () => {
    setIsBusy(true)
    eventTracking(events.join_contests, {
      EP_GameName:localStorage.getItem("activeGameName"),
       EP_GameType:JSON.parse(localStorage.getItem("activeGameType"))?.name, 
    EP_GameID:localStorage.getItem("activeGameId"), EP_ContestID:allData?._id});
      console.log("coinStore is ", coinStore)

    if (coinStore == false) {
      //uniqueIgn

      let allFeaturedData = JSON.parse(localStorage.getItem("joinTounament"));
      // let games = JSON.parse(
      //   localStorage.getItem(constants.profileLite)
      // )?.item?.gameNames.find(
      //   (val) => val?.game === allFeaturedData?.game?._id
      // );
      // var uniqueI = allData?.userInGameName;
      // var user_data = JSON.parse(localStorage.getItem(constants.profileLite));

      let data = {
        // type: 'contest',
        inGameUserName: currentName,
        user: walletDetails.user_id,
        contest: allData?._id,
        joinedDate: new Date(),
        JoinedTimestamp: new Date().getTime(),
        isActive: allData?.isActive,
      };

      if (
        allFeaturedData?.gameTypeAry?.players &&
        parseInt(allFeaturedData?.gameTypeAry?.players) > 1
      ) {
        // console.log(user_data?.item?.isMobileVerified);

        var numArr = [];

        var selectedPlayerArr = [];
        for (
          var i = 2;
          i <= parseInt(allFeaturedData?.gameTypeAry?.players);
          i++
        ) {
          numArr.push(i);

          selectedPlayerArr.push({
            num: i,
            user_id: null,
            gamerjiName: null,
            levelImage: null,
            inGameName: null,
          });
        }
        setIsBusy(false)
        setShowConfirm(false);
        setSelectedPlayerData(selectedPlayerArr);
        setTotal_players(selectedPlayerArr.length);
        setExtra_players(allFeaturedData?.gameTypeAry?.extraPlayers);
        setShowJoinTeam(true);
      } else {
        let response_check_date_time = await checkDateTime(data.contest);
        //console.log('response_check_date_time: ', response_check_date_time);
        if (response_check_date_time && "success" in response_check_date_time) {
          if (response_check_date_time.success === true) {
            let res = await onCreateContest(data);
            // console.log("res_contest_join: ", res);
            if (res.success === true) {
              setIsBusy(false)
              setShowConfirm(false);
              // window.location.href = "/contest-details/" + allFeaturedData?._id; //routehelp.contestdetails;
              navigate("/contest-details/" + allFeaturedData?._id); //routehelp.contestdetails;

            } else {
              // console.log("Error");
              setIsBusy(false)
              setShowConfirm(false);
              if (
                res.data.errors?.[0]?.msg ===
                "ALREADY_JOINED_MAX_FREE_TRANSACTION"
              ) {
                setShowPremiumPopup(true);
              } else {
                toast.error(res.data.errors?.[0]?.msg, {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            }
          } else {
            setIsBusy(false)
            setShowConfirm(false);
            toast.error(response_check_date_time.errors?.[0]?.msg, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      }
    } else {
      // window.location.href = routehelp.coinstore;
      //  console.log("curen 274 ",allData.contestFor?.inCurrency?.label)
      setIsBusy(false)
      setShowConfirm(false);
      toast.error(t('error_insufficient_balance_in_your_wallet'), {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      timer = setTimeout(()=>{
       
        navigate("/" + routehelp.coinStore, { state: 1 })
      },2000)
     
      // if (allData.contestFor?.inCurrency?.label)
      //   toast.error(t('error_insufficient_balance_in_your_wallet'), {
      //     position: "top-center",
      //     autoClose: 2000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //   });
      // else
      // {
      //   setIsBusy(false)
      //   setShowConfirm(false);
      //   navigate("/" + routehelp.coinStore, { state: 1 })
      // }
      
    }
  };

  const getdobnstate = (tag) => {
    // setShowjoinpopup(tag);
    var gameid = localStorage.getItem("activeGameId");
    findUserName(gameid);
    setShowdobnstate(tag);
    setShowConfirm(true);

  };

  const cancelDobNstate = () => {
    setShowdobnstate(false);
    // setShowjoinpopup(false);
    setShowConfirm(false);
  };

  const getWinnerClick = () => {
    setShowWinners(true);
    setAllWinners(allData);
  };

  const joinedContest = () => {
    // console.log(allData?.gameAry  )
    localStorage.setItem("joinTounament", JSON.stringify(allData));
    localStorage.setItem("activeGameId", allData?.gameAry?.[0]?._id);
    localStorage.setItem("activeGame", allData?.gameAry?.[0]?.name);
    eventTracking(events.contest_winners, {
       EP_GameName:localStorage.getItem("activeGameName"), 
    EP_GameType:JSON.parse(localStorage.getItem("activeGameType"))?.name, 
     EP_GameID:localStorage.getItem("activeGameId"), 
     EP_ContestID:allData._id});

    // window.location.href = `/contest-details/${allData?._id}`;
    navigate(`/contest-details/${allData?._id}`)
  };

  const backButtonSetShowJoinTeam = () => {
    setShowJoinTeam(false);
  };

  return (
    <div className="contest-list contest-tournament-list game_tounament">
      {/* <ToastContainer />   */}

      {/* DOBNSTATE Popup */}
      {showdobnstate && (
        <DobNStates
          submitDobNstate={getdobnstate}
          cancelDobNstate={cancelDobNstate}
          screen={dobNstateScreenName}
        />
      )}

      {/* Contenst - Tournament/{id} (One box) */}
      <div className="box">
        <h3 style={{ fontSize: "16px", fontWeight: "800" }}>
          {/* <marquee> */}
          {allData?.title}

          {/* </marquee> */}
          <span   onClick={() => {
                                          navigator.clipboard.writeText(
                                            allData?.shortCode
                                          );
                                          toast.info(t('info_Copied'), {
                                            position: "top-center",
                                            autoClose: 2000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                          });
                                        }} className="pointer">{t('contest.id')}: {allData?.shortCode}</span>
        </h3>
        <div className="row tournament_contest_row">
          <div className="col-12">
            <div className="inner_row">
              <div className="inner_item ">
                {t('contest.date')} <b>{getProfiledateFormat(allData?.date)}</b>
              </div>
              <div className="inner_item">
                {t('contest.time')} <b>{formatAMPM(new Date(allData?.time))}</b>
              </div>
              {allData?.titles?.slice(0, 2).map((itemInner, index) => {
                if (itemInner?.name !== "-" && itemInner?.isSelection) {
                  return (
                    <div className="inner_item" key={index}>
                      {itemInner?.name} <b className="text-ellipsis">{itemInner?.value}</b>
                    </div>
                  );
                }
              })}
            </div>
            <div className="inner_row bg-dark">
              <div className="inner_item inner_item_medium">
                {t('contest.prize_pool')} <br />{" "}
                <b>
                  <img
                    src={
                      url.imageUrl +
                      allData?.currency?.[0]?.outCurrency?.img?.default
                    }
                    alt=""
                    className="coin_img"
                  />{" "}
                  {allData?.prizePool}
                </b>
              </div>
              <div className="inner_item inner_item_medium">
                {allData.winningModel === "perRank" ? `${t('tournaments.winners')}` : `${t('contest.per_kill')}`}{" "}
                {allData.winningModel === "perRank" ? (
                  <b
                    className="pointer"
                    onClick={() => getWinnerClick(allData)}
                  >
                    {" "}
                    {allData?.totalWinners}{" "}
                    <i className="fas fa-chevron-down"></i>
                  </b>
                ) : (
                  <b className="">
                    <img
                      src={
                        url.imageUrl +
                        allData?.currency?.[0]?.outCurrency?.img?.default
                      }
                      alt=""
                      className="coin_img"
                    />{" "}
                    {allData?.killPoints}{" "}
                  </b>
                )}
              </div>
              <div className="inner_item inner_item_medium">
                {t('contest.join_using')} <br />{" "}
                {allData?.entryFee > 0 ? (
                  <b>
                    <img
                      src={
                        url.imageUrl +
                        allData?.currency?.[0]?.inCurrency?.img?.default
                      }
                      alt=""
                      className="coin_img"
                    />{" "}
                    {allData?.entryFee}
                  </b>
                ) : (
                  <b>{t('tournaments.free')}</b>
                )}
              </div>
            </div>
            <div className="avalible_wrap">
              <div className="inner">
                <span
                  style={{
                    width: progressWidth,
                  }}
                ></span>
              </div>
              <p className="d-flex justify-content-between text-light">
                <span>
                  {t('contest.players_remaining', { players: allData?.totalPlayers - allData?.totalJoinedPlayers })}
                </span>
                <span>{t('contest.players_joined', { players: allData?.totalJoinedPlayers })}</span>
              </p>
            </div>
            <div className="contest_foot p-2" >

              {allData?.totalJoinedPlayers >= allData?.totalPlayers ? (
                <div className="inner pointer">
                  <a className="contest_joining_popup">{t('tournaments.full')}</a>
                </div>
              ) : allData?.isJoined === true ? (
                <div className="inner pointer">
                  <a className="d-flex justify-content-center align-items-center">
                    <b
                      className="contest_joining_popup d-flex justify-content-center align-items-center"
                      onClick={() => joinedContest()}
                    >
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "25px" }}
                      >
                        {t('tournaments.joined')}
                      </div>

                      <div
                        className="tick-icon"
                      >
                        <Lottie
                          options={{
                            animationData: successCheck,
                            w: "20px",
                          }}
                        />
                      </div>
                    </b>
                  </a>
                </div>
              ) : (
                // <div className="inner pointer" onClick={() => getjoin(allData)}>
                //   <span className="contest_joining_popup">{t('contest.join_now')}</span>
                // </div>
                <button 
                style={{backgroundColor :'transparent', outline:'none', border:'none'}}     
                className="inner pointer text-center d-flex flex-row justify-content-center align-items-center" 
                disabled = {isBusy}
                onClick={() => getjoin(allData)}>
                   <span className="contest_joining_popup">{t('contest.join_now')}
                    {
                     isBusy && <img style={{ maxWidth: 20 }} src={roller} alt="roller"/>
                    }
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Winning Breakup - Contests */}
      {showWinners === true && (
        <div className="tc_popup_bg">
          <div
            className="custom-popup rules custom_scroll show"
            id="contact_popup"
          >
            <div className="popup-head">
              <h3>{t('contest_details.winning_breakup')}</h3>
              <button
                className="close-popup"
                onClick={() => setShowWinners(false)}
              >
                <img src={Close} alt="" />
              </button>
            </div>
            <div className="popup-body bg-yellow">
              <div
                className="all_sub_screens_div_related_to_ads"
                style={{ display: "inherit", marginBottom:'20px' }}
              >
                {allWinners?.pointpool && allWinners?.pointpool?.length !== 0 && (
                  <div
                    className="list-group main-tab w-90"
                    id="list-tab"
                    role="tablist"
                  >
                    <a
                      className="list-group-item list-group-item-action active"
                      id="list-home-list"
                      data-toggle="list"
                      href="#prize-1"
                    >
                      {t('contest_details.prize_pool')}
                    </a>
                    <a
                      className="list-group-item list-group-item-action"
                      id="list-profile-list"
                      data-toggle="list"
                      href="#point-2"
                    >
                      {t('contest_details.points')}
                    </a>
                  </div>
                )}
                <div className="tab-content" id="nav-tabContent">
                  <div className="tab-pane active" id="prize-1">
                    <div className="prize_pool mb-3 text-center p-2 w-90">
                      <p>{t('contest_details.prize_pool')}</p>
                      <h6>
                        <img
                          src={
                            url.imageUrl +
                            allWinners?.currency?.[0]?.outCurrency?.img
                              ?.default
                          }
                          alt=""
                          className="coin_img_big"
                        /> {" "}
                        {allWinners?.prizePool}
                      </h6>
                    </div>
                    <div className="table-responsive total_table total_table_border mb-3 w-90">
                      <table>
                        <tbody>
                          {allWinners?.prizePoolInfo?.map((item1, index1) => {
                            return (
                              <tr key={index1}>
                                <td className="text-left">
                                  {t('contest_details.rank')}:{" "}
                                  {item1?.rankTo !== "0" &&
                                    item1?.rankTo != item1?.rankFrom
                                    ? item1?.rankFrom + "-" + item1?.rankTo
                                    : item1?.rankFrom}
                                </td>
                                <th className="text-right">
                                  <img
                                    src={
                                      url.imageUrl +
                                      allWinners?.currency?.[0]?.outCurrency?.img?.default
                                    }
                                    alt=""
                                    className="coin_img"
                                  /> {" "}
                                  {item1?.amount}
                                </th>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {allWinners?.pointpool && (
                    <div className="tab-pane" id="point-2">
                      <div className="table-responsive total_table total_table_border mb-3 w-90">
                        <table>
                          <tbody>
                            {allWinners?.pointpool?.map((item1, index1) => {
                              return (
                                <tr key={index1}>
                                  <td className="text-left">
                                    {t('contest_details.rank')}:{" "}
                                    {item1?.rankTo !== "0"
                                      ? item1?.rankFrom + "-" + item1?.rankTo
                                      : item1?.rankFrom}
                                  </td>
                                  <th className="text-right">{item1?.point}</th>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>{
                !premiumUser &&

                <Advertisement
                  screen={
                    localStorage.getItem("apk_screens_list") === null
                      ? { code: "no_results" }
                      : JSON.parse(localStorage.getItem("apk_screens_list"))
                        .prizePoolPopup
                  }
                  screen_div_class_name=".all_sub_screens_div_related_to_ads"
                  screen_type="sub"
                />
              }
            </div>
          </div>
        </div>
      )}

      {/* Join Popup - joinContestWalletPopup */}
      {showConfirm === true && (
        <div className="tc_popup_bg">
          <div
            className={`custom-popup  confirm custom_scroll join show ${premiumUser ? "premium_active" : "rules"
              }`}
            id="contact_popup"
          >
            <div
              className={`popup-head ${premiumUser ? "premium_active" : ""}`}
            >
              {/* <marquee> */}
              <h3> {selectedFeaturedData?.title} </h3>
              {/* </marquee> */}

              <button
                className="close-popup"
                onClick={() => setShowConfirm(false)}
              >
                {/* {console.log(premiumUser)} */}
                <img src={premiumUser ? PremiumClose : Close} alt="" />
              </button>
            </div>
            <div
              className={`popup-body ${premiumUser ? "premium_active" : "bg-yellow"
                }`}
                style={{marginBottom:'20px'}}
            >
              <div className="all_sub_screens_div_related_to_ads">
                <p
                  className={`game_det ${premiumUser ? "premium_active" : ""}`}
                >
                  <span>
                    {/* {console.log("Test: ", selectedFeaturedData)} */}
                    {selectedFeaturedData?.gameAry[0]?.name} -{" "}
                    {selectedFeaturedData?.gameTypeAry?.name}
                  </span>
                  <span>
                    {getTournamentDateTime(
                      selectedFeaturedData?.date,
                      selectedFeaturedData?.time
                    )}
                  </span>
                </p>
                {premiumUser ? (
                  <>
                    <div className="ps_info">
                      <div className="ps_info_logo">
                        <img src={PremiumLogo} alt="Premium"/>
                      </div>
                      <div className="ps_info_detail">{t('as_a_Gamerji_Premium_User_you_can_join_unlimited_contests_for_free')}</div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="round_time">
                      <p>{t('contest.confirmation')}</p>
                      {selectedFeaturedData.currency?.[0]?.inCurrency?.code ==
                        "coin" && (
                          <p>
                            {t('contest.coin_balance')} ={" "}
                            <img
                              src={
                                url.imageUrl +
                                selectedFeaturedData?.currency?.[0]?.inCurrency
                                  ?.img?.default
                              }
                              style={{ width: "15px" }}
                              alt="coin"
                            />{" "}
                            {coin}{" "}
                          </p>
                        )}
                      <p>
                        {t('contest.diamond_balance')} ={" "}
                        <img
                          src={
                            url.imageUrl +
                            selectedFeaturedData?.currency?.[0]?.inCurrency
                              ?.img?.default
                          }
                          style={{ width: "15px" }}
                          alt="diamond"
                        />{" "}
                        {walletUsage?.winningAmount}{" "}
                      </p>
                    </div>
                  </>
                )}

                <div className="fee">
                  <p
                    className={`${premiumUser ? "premium_active border_white" : ""
                      }`}
                  >
                    <b>{t('contest.entry_fee')}</b>
                    <b>
                      {selectedFeaturedData.entryFee > 0 &&
                        <img
                          src={
                            url.imageUrl +
                            selectedFeaturedData?.currency?.[0]?.inCurrency
                              ?.img?.default
                          }
                          style={{ width: "15px" }}
                          className="coin_img"
                          alt="coin"
                        />}{" "}
                      {selectedFeaturedData.entryFee === "0"
                        ? t('tournaments.free')
                        : selectedFeaturedData.entryFee}
                    </b>
                  </p>
                  <p
                    className={`d-flex ${premiumUser ? "premium_active border_white" : ""
                      }`}
                    style={{ justifyContent: "space-between" }}
                  >
                    <span>
                      {/* <img src={pay} alt="" className="mCS_img_loaded mr-2" /> */}
                      {premiumUser ? t('private_contest.your_fee') : t('contest.to_pay')}
                    </span>
                    <b>
                      {premiumUser
                        ? ""
                        : selectedFeaturedData.entryFee > 0 &&
                        <img
                          src={
                            url.imageUrl +
                            selectedFeaturedData?.currency?.[0]?.inCurrency
                              ?.img?.default
                          }
                          style={{ width: "15px" }}
                          className="coin_img"
                          alt="coin"
                        />}{" "}
                      {premiumUser
                        ? t('tournaments.free')
                        : selectedFeaturedData.entryFee === "0"
                          ? t('tournaments.free')
                          : selectedFeaturedData.entryFee}
                    </b>
                  </p>
                </div>
                {selectedFeaturedData?.currency?.joiningNote != "" && (
                  <p className="terms_join" style={{ fontSize: "14px" }}>
                    {selectedFeaturedData?.currency?.joiningNote}
                  </p>
                )}
                {selectedFeaturedData?.game?.settings?.levelNote != "" && (
                  <p className="level_note">
                    {selectedFeaturedData?.game?.settings?.levelNote}
                  </p>
                )}
                {premiumUser && selectedFeaturedData.entryFee > "0" ? (
                  <>
                    {" "}
                    <div className="ps_coin_saved">
                      <p>
                        <b>
                          {" "}
                          {
                            selectedFeaturedData?.currency?.[0]?.inCurrency
                              ?.code[0].toUpperCase() + selectedFeaturedData?.currency?.[0]?.inCurrency
                                ?.code.slice(1)
                          }{" "}
                          Saved{" "}
                        </b>
                        <b>
                          {selectedFeaturedData.entryFee > 0 &&
                            <img
                              src={
                                url.imageUrl +
                                selectedFeaturedData?.currency?.[0]?.inCurrency?.img?.default
                              }
                              style={{ width: "15px" }}
                              className="coin_img"
                              alt="coin"
                            />}{" "}
                          {selectedFeaturedData.entryFee === "0"
                            ? "0"
                            : selectedFeaturedData.entryFee

                          }
                        </b>
                      </p>
                    </div>{" "}
                  </>
                ) : (
                  <></>
                )}
                <button
                  className={`btn  main-btn next ${premiumUser ? "premium_active_btn" : "btn-dark"
                    }`}
                  onClick={() => onJoinTournament()}
                  disabled={isBusy}
                >
                  {t('contest.join_contest')}{" "}
                  
                  <img src={isBusy ? roller :  premiumUser ? rightArrowBlack : rightArrow} alt="" className="mCS_img_loaded" />
                </button>
              </div>
              {
                !premiumUser &&

                <Advertisement
                  screen={
                    localStorage.getItem("apk_screens_list") === null
                      ? { code: "no_results" }
                      : JSON.parse(localStorage.getItem("apk_screens_list"))
                        .joinContestWalletPopup
                  }
                  screen_div_class_name=".all_sub_screens_div_related_to_ads"
                  screen_type="sub"
                />
              }
            </div>
          </div>
        </div>
      )}

      {showPremiumPopup === true && (
        <div className="tc_popup_bg">
          <div
            className={`custom-popup  confirm custom_scroll join show premium_active rules`}
            id="contact_popup"
            style={{bottom:'3%'}}
          >
            <div className={`popup-head  premium_active`}>
              {/* <marquee> */}
              <h3> {selectedFeaturedData?.title} </h3>
              {/* </marquee> */}

              <button
                className="close-popup"
                onClick={() => setShowPremiumPopup(false)}
              >
                {/* {console.log(premiumUser)} */}
                <img src={PremiumClose} alt="" />
              </button>
            </div>
            <div className="ps_header" style={{ height: "150px" }}>
              <div className="ps_logo">
                <img src={Logo} alt="Logo"/>
              </div>
              <div className="ps_name">{t('tournaments.premium')}</div>
            </div>
            <div className="ps_popup_main">
              <div className="ps_popup_p">
                <p>{t('premium.you_have_already_joined_today', { title: `contest`, total: localStorage?.getItem('noOfContest') || 1 })}</p>
              </div>
              <div className="ps_popup_p layer2">
                <p>{t('premium.to_join_a_tournament')},</p>
                <p>{t('premium.subscribe_to_gamerji')}</p>
              </div>
              <div className="ps_popup_p">
                <p>{t('premium.premium_users_get_to_join_unlimited_contests_tournaments_for_free_and_many_more_other_benefits')}</p>
              </div>
            </div>

            <button
              className={`btn premium_active_btn`}
              onClick={() => navigate(routehelp.premiumSubscription)}
              style={{
                textAlign: "center",
                width: "80%",
                margin: "0 auto",
                marginBottom: "30px",
              }}
            >
              {t('sidebar.explore_premium')}
            </button>
          </div>
        </div>
      )}

      {/* SQAUD registrations */}
      {showJoinTeam === true && (
        <SquadRegistration
          selectedPlayerData={selectedPlayerData}
          selectedContest={""}
          backButtonSetShowJoinTeam={backButtonSetShowJoinTeam}
          total_players={total_players}
          extra_players={extra_players}
          isContest={true}
        />
      )}
    </div>
  );
}

export default ContestStructure;
