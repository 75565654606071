import React, { Fragment, useEffect, useState } from "react";
// import useRazorpay, { RazorpayOptions } from "react-razorpay";
import getSymbolFromCurrency from "currency-symbol-map";
import currencyToSymbolMap from "currency-symbol-map/map";

import { useNavigate } from "react-router-dom";
import {
  getCoinStoreList,
  getAllAvatarList,
  getAllRewardCategoryList,
  getAllRewardProductMyList,
  requestCreateCoinStoreTransactions,
  requestCreateCFCoinStoreTransactions,
  getApplyReward,
  getBuyAvatar,
  getUserProfile,
  getGenrateSignature,
  createXsollaTransaction,
  xsollaList,
  createAirPayTransaction,
  verifyAirPayTransaction,
  getUserProfileLite,
  epCreatePayment
} from "../apis";
import { routehelp } from "../config/routehelp";
import Previous from "../assets/images/previous.svg";
import Wallet from "../assets/images/wallet.svg";
import url from "../constants/url";
import Dollar from "../assets/images/upload-card.svg";
import Coin from "../assets/images/easypaisa.png";
import Myntra from "../assets/images/myntra.png";
import Diamond from "../assets/images/BLUE_Diamond.png";
import myRecentTransactionImage from "../assets/images/ic_transactions.png";
import Rupee from "../assets/images/rupee.svg";
import { Link, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import Cancel from "../assets/images/round_not_qualified.png";
import Close from "../assets/images/close.svg";
import {
  getConvertionDateTime,
  checkDobNstateConditions,
} from "../component/common";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SuccessPayment from "../assets/images/success-payment.png";
import PendingPayment from "../assets/images/pending-payment.png";
import FailPayment from "../assets/images/fail-payment.png";
import PurchasePackIcon from "../assets/images/purchase-pack-icon.png";
import AccountIcon from "../assets/images/account_icon.png";
import sha256 from "crypto-js/sha256";
import helperFunc from "../helperFuncs";
import HmacSHA256 from "crypto-js/hmac-sha256";
import Base64 from "crypto-js/enc-base64";
// import { loadStripe } from "@stripe/stripe-js";
// import { useStripe } from "@stripe/react-stripe-js";
import Loader from "../component/loader";
import { constants } from "../constants/constants";
import DobNStates from "./dobnstate";
import Advertisement from "../component/advertisement";
import EarnCoins from "./EarnCoins";
import QRCode from "react-qr-code";
import { useTranslation, Trans } from "react-i18next";
import Cookies from 'js-cookie'
import Arrow from '../assets/images/right-arrow.svg'
import PremiumClose from "../assets/images/cancel_premium.png"
import CreatePayNow from "../component/CreatePayNow";
import { eventTracking } from "../firebaseAnalytics";
import { events } from "../utils/appEvents";
import { useProfile } from "../component/ProfileComponent/ProfileContext";

// var stripeAPIKey = helperFunc.getStripeAPIKey();
// const stripePromise = loadStripe(stripeAPIKey);

function CoinStore(props) {
  // DobNState Screen Name
  // const location = useLocation()
  // console.log(location.state)
  const dobNstateScreenNameBuyItem = "coin-store-buy-item";
  const dobNstateScreenNameRedeemItem = "coin-store-redeem-item";
  const { t } = useTranslation()

  // const Razorpay = useRazorpay();
  const navigate = useNavigate();
  const [allCoin1, setAllCoin1] = useState(null);
  const [allCoin2, setAllCoin2] = useState(null);
  const [allCoin3, setAllCoin3] = useState(null);
  const [allReward1, setAllReward1] = useState(null);
  const [allReward2, setAllReward2] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [activeCoinStoreTab, setActiveCoinStoreTab] = useState(0);
  const [activeReward, setActiveReward] = useState(0);
  const [userProfile, setUserProfile] = useState(null);
  const [redeemData, setRedeemData] = useState({});

  const [showRedeem, setShowRedeem] = useState(false);

  const [infoTitle, setInfoTitle] = useState("");
  const [infoDesc, setInfoDesc] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showBundlesInfo, setShowBundlesInfo] = useState(false);
  const [avatarData, setAvatarData] = useState({});
  const [bundlesData, setBundlesData] = useState({});
  const [cfOrderData, setCFOrderData] = useState({});

  const [tagShowDobNstate, setTagShowDobNstate] = useState(false);
  const [buyItemData, setBuyItemData] = useState([]);
  const [token, setToken] = useState("");
  const [tagShowHowToRedeemPopup, setTagShowHowToRedeemPopup] = useState([]);
  const [currency, setCurrency] = useState("");
  const [isQrCode, setIsQrCode] = useState(false);
  const [transactionUniqueID, setTransactionUniqueID] = useState();
  // const [transactionData, setTransactionData] = useState(null);
  const [timer, setTimer] = useState(5 * 60 * 1000);
  const currentLang = Cookies.get('i18next') || 'EN'

  const param = useLocation()
  var userCountry = helperFunc.getUserCountry();
  // let isDefault = JSON.parse(localStorage.getItem("isDefault"));
  const [transactionId, setTransactionId] = useState('')
  const [createTimeTn, setCreateTimeTn] = useState('')
  const [showPayNow, setPaynow] = useState(false)
  const { walletDetails, updateWalletDetails } = useProfile()
  // const [loading, setLoading] = useState(false)
  useEffect(()=>{
    EventTracking(); 
    // setActiveCoinStoreTab(1)
  },[]);

  const EventTracking=()=>{
    eventTracking(events.reward_store_menu);
  }
  
  useEffect(() => {
    const clientSecretFromURL = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    const coinStoreIdFromURL = new URLSearchParams(window.location.search).get(
      "coinStore"
    );

    if (clientSecretFromURL) {
      createStripeTransactionWeb(coinStoreIdFromURL, clientSecretFromURL);
    }
  }, []);

  useEffect(() => {
    // console.log("showLoader", param?.state);
    if (param?.state){
      fetchData()
      setActiveTab(1)
      setActiveCoinStoreTab(0)
    }
    else{
    fetchRewardsList()

    }

  }, []);
  useEffect(() => {
    var count;
    // var count1;

    if (isQrCode) {
      count = setInterval(() => verifyAirpay(), 15000);
      // setInterval(() => setTimer(timer - 1), 1000);
      setTimeout(() => {
        clearInterval(count)
        // clearInterval(count1)
        setIsQrCode(false);
      }, 5 * 60 * 1000)
    }


    return () => clearInterval(count);
  }, [isQrCode]);

  const verifyAirpay = async () => {
    let payload = {
      transactionUniqueID: transactionUniqueID
    };

    let data = await verifyAirPayTransaction(payload);
    // console.log(data);
    if (data?.status !== "INITIATED") {
      // console.log('jaimin')
      navigate('/airpay-status', { state: data })
      // setTransactionData(data.data)
    } else {
      // console.log('patel')
    }
  }

  const createStripeTransactionWeb = async (
    coinStoreIdFromURL,
    clientSecretFromURL
  ) => {
    let res = await createStripeTransactionWeb({
      coinStore: coinStoreIdFromURL,
      clientSecret: clientSecretFromURL,
    });
    if (res) {
      // console.log("createStripeTransactionWeb==>res?.item===>", res?.item);
      //setClientSecret(res?.item?.clientSecret);
    }
  };

  useEffect(() => {
    localStorage.setItem("platform", "WebApp");
    // fetchData();
    // fetchProfileData();
  }, []);

  const getTotalCoin = (wallet) => {
    let index = wallet?.findIndex((obj) => obj.currencyData?.code == process.env.REACT_APP_CURRENCY_CODE);

    if (index > -1) {
      return wallet[index].winningAmount ? wallet[index].winningAmount : 0;
    }
    return 0;
  };
  const getTotalDiamond = (wallet) => {
    let index = wallet.findIndex((obj) => obj.currencyData?.code == process.env.REACT_APP_CURRENCY_CODE_OUT);

    if (index > -1) {
      return wallet[index].winningAmount ? wallet[index].winningAmount : 0;
    }
    return 0;
  };

  const fetchProfileData = async () => {
    // console.log("**********called")
    setShowLoader(true)
    try{
      let res = await getUserProfileLite();
      if (res) {
        setUserProfile(res);
        // console.log("res==>", res);
        localStorage.setItem("currentCoin", getTotalCoin(res?.item?.wallet));
        localStorage.setItem(
          "currentDiamond",
          getTotalDiamond(res?.item?.wallet)
        );
        setShowLoader(false)
      }
      else {
        console.log("errror")
      }
    }
    catch(error){
      setShowLoader(false)
    }
    
  };


  const fetchData = async () => {
    // console.log("default", isDefault)
    // if (isDefault) {
      let one = {
        filter: { coinType: "1" },
        skip: 0,
        limit: 10,
        sort: "asc",
        sortBy: "amount",
      };
      let res = await getCoinStoreList(one);
      // console.log(res.list)
      if (res) {
        setAllCoin1(res?.list);
      }
      
   
    // }
    
    // getMyRewardList();
  };

  const fetchBundles = async()=>{
    setShowLoader(true)
    try{

    
    let two = {
      filter: { coinType: "2" },
      skip: 0,
      limit: 100,
      sort: "asc",
      sortBy: "amount",
      user_id:JSON.parse(localStorage.getItem('profileLite'))?.item?.user_id
    };
    let res1 = await getCoinStoreList(two);
    // console.log(res1)
    if (res1) {
      setAllCoin2(res1?.list);
      setShowLoader(false)
    }
    else{
      setShowLoader(false)
    }
  }catch(error){
    setShowLoader(false)
    console.log(error)
  }
  }
const fetchAvatars = async() =>{
  setShowLoader(true)
try{
   let three = { skip: 0, limit: 100, sort: "asc", sortBy: "amount" };
      let res2 = await getAllAvatarList(three);
      if (res2) {
        setAllCoin3(res2?.list);
        setShowLoader(false)
      }
      else{
        setShowLoader(false)
      }
}
catch(error){
  setShowLoader(false)
  console.log("error")
}
}
  const fetchRewardsList = async()=>{
    setShowLoader(true)
    try{
      let four = { skip: 0, limit: 100, sort: "asc", sortBy: "order" };
      let res3 = await getAllRewardCategoryList(four);
      if (res3) {
        setAllReward1(res3?.list);
        
      }
      setShowLoader(false)
    }
    catch(error){
      setShowLoader(false)
      console.log(error)
    }
   
  }

  const getMyRewardList = async () => {
    setShowLoader(true)
    try{
      let five = { skip: 0, limit: 100, sort: "asc", sortBy: "createdAt" };
      let res4 = await getAllRewardProductMyList(five);
      if (res4) {
        // console.log("setAllReward2: ", res4?.list);
        let temp = [];
        res4?.list?.forEach(() => {
          temp.push(false);
        });
        setAllReward2(res4?.list);
        setTagShowHowToRedeemPopup(temp);
      }
      setShowLoader(false)
    }
    catch(error){
      setShowLoader(false)
      console.log("error", error)
    }
    
  };
  const createAirpayTransaction = async (item) => {
    setShowLoader(true);
    // let countryObj = JSON.parse(localStorage.getItem("userCountryObj"));
    let payload = {
      coinStore: item._id,
      isMobile: false
    };

    let data = await createAirPayTransaction(payload);
    if (data?.success) {
      setToken(data?.data?.token);
      setTransactionUniqueID(data?.data?.transactionUniqueID);
      setIsQrCode(true);
      setShowLoader(false);
      // let url = data?.data?.token;
      // window.location.href = url;
    } else {
      setShowLoader(false);
    }
  }

  const moveToPaymentOptionScreen = async (item) => {
    //  const userProfileObj = JSON.parse(userProfile);
    const userProfileObj = userProfile;

    // console.log("userProfile.rzpKey==>", userProfile);
    // debugger
    //console.log(
    //   "userProfile.razorPayEnabled==>",
    //   userProfileObj.razorPayEnabled
    // );

    window.location.href = "/payment-gateway-web/" + item._id;

    // console.log(item._id);
    // if(item._id)
    //   window.location.href = "/response/" + item._id;

    // if (userProfile?.country?.code === "IN") {
    //   // window.location.href = "/payment-gateway/" + item._id;
    //   if (userProfileObj?.razorPayEnabled === false) {
    //     //  alert(userProfileObj?.razorPayEnabled);

    //     let payload = {
    //       amount: item.amount,
    //       coinStore: item._id,
    //       paymentType: 17,
    //     };
    //     let createCFCoinStoreTransactionsResponse =
    //       await requestCreateCFCoinStoreTransactions(payload);

    //     if (
    //       createCFCoinStoreTransactionsResponse &&
    //       createCFCoinStoreTransactionsResponse.item
    //     )
    //       cashfreePayment(
    //         createCFCoinStoreTransactionsResponse.item,

    //       );

    //     // window.location.href = "/payment-options";
    //   } else {

    //     let payload = {
    //       amount: item.amount,
    //       coinStore: item._id,
    //       paymentType: 17,
    //     };
    //     let createCoinStoreTransactionsResponse =
    //       await requestCreateCoinStoreTransactions(payload);

    //     if (
    //       createCoinStoreTransactionsResponse &&
    //       createCoinStoreTransactionsResponse.item
    //     )
    //       razorpayPayment(
    //         createCoinStoreTransactionsResponse.item.order,
    //         userProfileObj.rzpKey
    //       );
    //   }

    // } else {
    //   window.location.href = "/payment-gateway-web/" + item._id;
    //  // window.location.href = "/payment-options/" + item._id;
    // }
  };

  const cashfreePayment = async (order) => {
    var cfdatakeysArr = [
      "appId",
      "orderId",
      "orderAmount",
      "orderCurrency",
      "orderNote",
      "customerName",
      "customerPhone",
      "customerEmail",
      "returnUrl",
      "notifyUrl",
    ];
    var cfdataArr = [];
    cfdataArr["appId"] = "58874f6db6934ba819cab399247885";
    cfdataArr["orderId"] = order?.transactionUniqueID;
    cfdataArr["orderAmount"] = order?.amount;
    cfdataArr["orderCurrency"] = "INR";
    cfdataArr["orderNote"] = "Buy Coin";
    cfdataArr["customerName"] = userProfile?.item?.gamerjiName;
    cfdataArr["customerPhone"] = userProfile?.item?.phone;
    cfdataArr["customerEmail"] = userProfile?.item?.email;
    cfdataArr["returnUrl"] = url.publicUrl + "return-payment";
    cfdataArr["notifyUrl"] = url.publicUrl + "verify-payment";

    var signature = await genrateSignature(cfdatakeysArr, cfdataArr);
    var cfdata = {
      appId: "58874f6db6934ba819cab399247885",
      orderId: order?.transactionUniqueID,
      orderAmount: order?.amount,
      orderCurrency: "INR",
      orderNote: "Buy Coin",
      customerName: userProfile?.item?.gamerjiName,
      customerPhone: userProfile?.item?.phone,
      customerEmail: userProfile?.item?.email,
      returnUrl: url.publicUrl + "return-payment",
      notifyUrl: url.publicUrl + "verify-payment",
      signature: signature,
    };
    setCFOrderData(cfdata);
    document.getElementById("redirectForm").submit();
  };

  const razorpayPayment = async (order, rzpKey) => {
    // console.log("userProfile.item==>", userProfile);
    //  const userProfileObj = JSON.parse(userProfile);
    const userProfileObj = userProfile;

    // console.log("userProfile.item==>", userProfileObj.item);

    // window.location.href = "/payment-options";

    const options = {
      description: "Credits towards service",
      image: "https://www.gamerji.tech/leagueops/media/logos/256x256.png",
      currency: "INR",

      key: rzpKey,
      amount: "5000",
      //  amount: "order.amount",
      order_id: order.id,

      name: "Gamerji E-sports Private Limited",
      handler: function (response) {
        // console.log("response=>", response);
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
        toast.success(t('success_Payment_successfull'), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
      prefill: {
        email: userProfileObj.item.email || "",
        contact: userProfileObj.item.phone,
        name: userProfileObj.item.gamerjiName || "",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#070B28",
      },
    };

    // const rzp1 = new Razorpay(options);

    // rzp1.on("payment.failed", function (response) {
    //   // alert(response.error.code);
    //   // alert(response.error.description);
    //   // alert(response.error.source);
    //   // alert(response.error.step);
    //   // alert(response.error.reason);
    //   // alert(response.error.metadata.order_id);
    //   // alert(response.error.metadata.payment_id);
    //   toast.error(response.error.description, {
    //     position: "top-center",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // });

    // rzp1.open();
  };

  const goback = () => {
    if (userCountry?.code === "IN") {
      window.location.href = "/account";
    } else {
      window.location.href = "/";
    }
  };

  const onClickRedeem = (item) => {
    setShowRedeem(true);
    setRedeemData({
      id: item?._id,
      name: item?.name,
      description: item?.description,
      coinAmount: item?.coinAmount,
      img: item?.currency.img.default,
    });
  };

  const OnClickRedeem = async () => {
    // console.log("redeem_data: ", redeemData);
    let data = { product: redeemData?.id };
    setShowRedeem(false);
    setShowLoader(true);
    let res = await getApplyReward(data);
    // console.log("Redeem Reward: ", res);


    if (res) {
      if (res.success && res.success === true) {
        // fetchProfileData();
        updateWalletDetails()

        getMyRewardList();

        setActiveReward(1);
        toast.success(t('redeem_reward_successfully'), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShowLoader(false);
      } else {
        showError(res.data?.errors?.[0]?.msg);
        setShowLoader(false);
      }
    } else {
      showError(t('error_failed_to_load_reponse_data'));
      setShowLoader(false);
    }
  };

  const onClickInfo = (item) => {
    setShowInfo(true);
    setAvatarData(item);
  };

  const onClickBundlesInfo = (item) => {
    setShowBundlesInfo(true);
    setBundlesData(item);
  };

  const genrateSignature = async (itemKeys, signatureDataArr) => {
    var signatureData = "";

    itemKeys.sort();
    // console.log("itemKeys==>", itemKeys);
    // console.log("signatureDataArr==>", signatureDataArr);

    itemKeys.forEach((element, index) => {
      signatureData += element + signatureDataArr[element];
    });

    var hash = HmacSHA256(
      signatureData,
      "dd332db9b5c7010b7e5e834e5b5f2c9c9fda1145"
    );
    var hashInBase64 = Base64.stringify(hash);
    // console.log("hashInBase64==>", hashInBase64);

    return hashInBase64;
    // console.log("hashInBase64==>", hashInBase64);
    // if(signatureDataArr.length>0 && signatureDataArr.length===item.length){
    //   let res = await getGenrateSignature(signatureDataArr);
    //   console.log("test==>",res);
    //   if (res) {
    //     return res;
    //   }
    // }

    //return signatureData;
    // return Base64.stringify(sha256(signatureData, "dd332db9b5c7010b7e5e834e5b5f2c9c9fda1145"));
  };

  const OnClickBuyAvatar = async (avatarId) => {
    // alert("Buy Avtar");

    let data = { avatar: avatarId };
    let res = await getBuyAvatar(data);
    // console.log("Buy Avatar: ", res);
    eventTracking(events.buy_avatar ,{EP_AvatarID:avatarId});

    if (res) {
      setShowInfo(false);
      if (res.success === true) {
        let three = { skip: 0, limit: 100, sort: "asc", sortBy: "amount" };
        let res2 = await getAllAvatarList(three);
        if (res2) {
          setAllCoin3(res2?.list);
        }

        toast.success(t('avatar_buy_successfully'), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      updateWalletDetails()

      } else {
        console.log("jfdsjfnjdjh",res.data?.errors?.[0]?.msg)
        toast.error(res.data?.errors?.[0]?.msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      // fetchProfileData();
    }
  };
const [coinAmount, setCoinAmount] = useState(0)
const [message, setMessage] = useState('')
const [resultCode, setResultCode] = useState('')


const createEpPayment = async(body)=>{
  localStorage.removeItem('paymentTransactionID')
  // console.log("skfd*******************************")
  const res = await epCreatePayment(body)
  // console.log(res?.data?.data)

  if (res?.data?.data?.paymentTransactionID) {

    setTransactionId(res?.data?.data?.paymentTransactionID)
    localStorage.setItem('paymentTransactionID',res?.data?.data?.paymentTransactionID)
    localStorage.setItem('paymentMessage',res?.data?.data?.result?.resultMessage)

    setCreateTimeTn(res?.data?.data?.paymentCreateTime)
    setShowLoader(false)
    setMessage(res?.data?.data?.result?.resultMessage)
    setResultCode(res?.data?.data?.result?.resultStatus)
    // setPaynow(true)
    setCoinAmount(body?.amount)
    if(localStorage.getItem('emode') == 'off')
    window.location.href = `/xsolla-status?paymentStatus=S&&paymentTransactionID=${res?.data?.data?.paymentTransactionID}&&resultCode=9000`
  else
    window['callCoinStore'](res?.data?.data?.paymentTransactionID)

  }
  else{

    window.location.href = `/xsolla-status?paymentStatus=F&&paymentTransactionID=null&&resultCode=4000`
  }

}
const [symbol, setSymbol] = useState("PKR")
  const onClickBuyItem = async(item) => {
    // console.log("clikedd******************")
    setShowLoader(true)
    localStorage.removeItem("subscriptionId")
        localStorage.removeItem("coinStoreId")
    localStorage.setItem("coinStoreId", item._id);
    setBuyItemData(item);
    // if(userProfile.item?.email === undefined || userProfile.item?.email === null ||
    //   userProfile.item?.email === "") {
    if (checkDobNstateConditions(dobNstateScreenNameBuyItem)) {
      setShowLoader(false)
      setTagShowDobNstate(true);
    } else {
      // createAirpayTransaction(item)
      // moveToPaymentOptionScreen(item);
      var body = {
        paymentOrderTitle: item._id,
        amount: item?.amount,
        type:"1"
      }
      eventTracking(events.buy_packs ,{EP_PackID:item?._id});
      eventTracking(events.packs_pay_now ,{EP_CoinstoreID:item?._id, EP_PackID:item?._id, EP_PAckAmount:item?.amount});
      setSymbol(item?.currency?.symbol)
      createEpPayment(body)
    }
  };

  const onClickBuyItem2 = (bundlesData) => {
    localStorage.removeItem("subscriptionId")
    localStorage.removeItem("coinStoreId")
    localStorage.setItem("coinStoreId", bundlesData._id);
    eventTracking(events.buy_bundle ,{EP_BundleID:bundlesData._id});
    setBuyItemData(bundlesData);
    // if(userProfile.item?.email === undefined || userProfile.item?.email === null ||
    //   userProfile.item?.email === "") {
    if (checkDobNstateConditions(dobNstateScreenNameBuyItem)) {
      setTagShowDobNstate(true);
    } else {
      // moveToPaymentOptionScreen(bundlesData);
      // createAirpayTransaction(bundlesData)
      var body = {
        paymentOrderTitle: bundlesData._id,
        amount: bundlesData?.amount,
        type:"2"
      }
      eventTracking(events.bundles_pay_now ,{EP_CoinstoreID:bundlesData._id , EP_BundleID:bundlesData._id, EP_BundleAmount:bundlesData?.amount});

      createEpPayment(body)
    }
  };

  const onClickRedeemItem = () => {
    // if(userProfile.item?.email === undefined || userProfile.item?.email === null ||
    //   userProfile.item?.email === "") {
    if (checkDobNstateConditions(dobNstateScreenNameRedeemItem)) {
      setTagShowDobNstate(true);
    } else {
      OnClickRedeem();
    }
  };

  const submitDobNstateForBuyItem = (tag) => {
    setTagShowDobNstate(tag);
    // console.log(JSON.parse(localStorage.getItem("isDefault")))
    // if (!isDefault) {
    //   moveToPaymentOptionScreen(buyItemData);
    // }
  };

  const submitDobNstateForRedeemItem = (tag) => {
    setTagShowDobNstate(tag);
    OnClickRedeem();
  };

  const cancelDobNstate = () => {
    setTagShowDobNstate(false);
  };



  const createHowToRedeemPopup = (html_data, index) => {
    return (
      <div className="coin_store_htr_main_div">
        <ToastContainer />
        <div className="coin_store_htr_popup">
          <div className="coin_store_htr_header">
            <div className=""></div>
            <div className="coin_store_htr_header_text">{t('coin_store.how_to_redeem')}</div>
            <div className="coin_store_htr_cancel_icon">
              <img
                src={Cancel}
                onClick={() => {
                  let temp = [...tagShowHowToRedeemPopup];
                  temp[index] = false;
                  setTagShowHowToRedeemPopup(temp);
                }}
              />
            </div>
          </div>

          <div className="coin_store_htr_body">
            <div className="all_sub_screens_div_related_to_ads">
              <div className="coin_store_htr_body_scrollable_component">
                {/* <div
                  className="coin_store_htr_text"
                  dangerouslySetInnerHTML={{
                    __html: html_data?.stepsOfRedeem,
                  }}
                ></div> */}
               { console.log(html_data?.stepsOfRedeem)}
                <ul className="text-left w-100">
                  {
                    html_data?.stepsOfRedeem.map((ele, ind) =>(
                      <li className="text-dark">{ele?.value}</li>
                    ))
                  }
                </ul>
              </div>
            </div>
            {/* <Advertisement
              screen={
                localStorage.getItem('apk_screens_list') === null ?
                  { code: 'no_results' }
                  :
                  JSON.parse(localStorage.getItem('apk_screens_list')).joinViaInviteCodePopup
              }
              screen_div_class_name='.all_sub_screens_div_related_to_ads'
              screen_type='sub'
            /> */}
          </div>
        </div>
      </div>
    );
  };
  const QrCodePopUp = (index) => {
    return (
      <div className="coin_store_htr_main_div">
        <ToastContainer />
        <div className="coin_store_htr_popup">
          <div className="coin_store_htr_header">
            <div className=""></div>
            <div className="coin_store_htr_header_text">{t('coin_store.qr_code')}</div>
            <div className="coin_store_htr_cancel_icon">
              <img
                src={Cancel}
                onClick={() => {
                  let temp = [...tagShowHowToRedeemPopup];
                  temp[index] = false;
                  setTagShowHowToRedeemPopup(temp);
                  setIsQrCode(false)
                }}
              />
            </div>
          </div>
          <div className="coin_store_htr_body">
            <div className="all_sub_screens_div_related_to_ads">
              <div className="coin_store_htr_body_scrollable_component">
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={token}
                  viewBox={`0 0 256 256`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const showError = (error) => {
    toast.error(error, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // get Catolog
  const [currencySymbol, setCurrencySymbol] = useState("INR");

  useEffect(() => {
    localStorage.setItem("platform", "WebApp");

    // console.log(">>>", activeCoinStoreTab);
    // fetchData();
    // fetchProfileData();
    // console.log("isDefault", isDefault);
    // if (isDefault) {
    //   setVirtualItem([]);
      // if (activeCoinStoreTab == 0)

      // not include in project
      // setAllCoin1([]);

      // if (activeCoinStoreTab == 1)
      
      //not included in project
      //  setAllCoin2([]);
      //  virtualItems();
    // }
  }, [activeCoinStoreTab]);

  const [virtualItem, setVirtualItem] = useState([]);
  const [currencyImg, setCurrencyImg] = useState("");
  const virtualItems = async () => {
    // console.log("called virtual items");
    setShowLoader(true);
    // console.log("show>>", showLoader);
    let countryObj = JSON.parse(localStorage.getItem("userCountryObj"));
    // console.log(countryObj);
    // console.log(url.imageUrl + countryObj?.flag?.default);
    setCurrencyImg(countryObj?.flag?.default);
    // xsolla virtual items call
    //   const query = new URLSearchParams({
    //     limit: '5',
    //     offset: '0',
    //     locale: 'en',
    //     'additional_fields[]': 'string',
    //     country: countryObj.code
    //   }).toString()
    //   const projectId = '216428' // '44056'
    //   console.log("activetab", activeCoinStoreTab+1)
    //   const url1 = `https://store.xsolla.com/api/v2/project/${projectId}/items/virtual_items/group/${activeCoinStoreTab+1}?${query}`
    //   // const url1=  `https://store.xsolla.com/api/v2/project/${projectId}/items/virtual_items?${query}`
    //   const res = await fetch(url1,{
    //     method: 'GET',
    //   })
    //   const resp = await res.text()
    //   if(resp)
    //     setVirtualItem(JSON.parse(resp)?.items)
    //   console.log("virtual items",JSON.parse(resp)?.items)
    //   console.log(JSON.parse(resp)?.items?.[0].price?.amount)

    // xsolla list api call
    // console.log(countryObj);
    if (activeCoinStoreTab + 1 != 3) {
      const body = {
        group_id: activeCoinStoreTab + 1,
        limit: 10,
        skip: 0,
        country: countryObj?.code,
      };
      const res = await xsollaList(body);
      // console.log("res>>>>", res);
      if (res?.success) {
        setVirtualItem(res?.items);
        setCurrencySymbol(
          getSymbolFromCurrency(res?.items[0]?.price?.currency)
        );
        localStorage.setItem("currecySymbol", res?.items[0]?.price?.currency);
      }
    } else {
      let three = { skip: 0, limit: 100, sort: "asc", sortBy: "amount" };
      let res2 = await getAllAvatarList(three);
      if (res2) {
        setAllCoin3(res2?.list);
      }
    }
    setShowLoader(false);
  };

  // xsolla order creation
  // async function orderCreation(item_sku, amount, coin, type) {
  //   if (checkDobNstateConditions(dobNstateScreenNameBuyItem)) {
  //     setTagShowDobNstate(true);
  //   } else {
  //     setShowLoader(true);
  //     let countryObj = JSON.parse(localStorage.getItem("userCountryObj"));
  //     let payload = {
  //       sku: item_sku,
  //       userCurrency: countryObj.code,
  //       // amount: parseInt(amount),
  //       amount: amount,
  //       type: type,
  //     };

  //     let data = await createXsollaTransaction(payload);
  //     if (data?.success) {
  //       let transactionUniqueID = data?.data?.transactionUniqueID;
  //       let body = {
  //         transactionUniqueID: transactionUniqueID,
  //         coin: coin,
  //         sku: item_sku,
  //         // amount: parseInt(amount),
  //         amount: amount,
  //       };
  //       localStorage.setItem("body", JSON.stringify(body));
  //       setToken(data?.data?.token);
  //       setShowLoader(false);
  //       // if (checkDobNstateConditions(dobNstateScreenNameBuyItem)) {
  //       //   setTagShowDobNstate(true);
  //       // } else {

  //       let url = `https://sandbox-secure.xsolla.com/paystation4/?token=${data?.data?.token}`;
  //       window.location.href = url;
  //       // }
  //     }
  //   }
  // }
  // xsolla payment creation

  //<div className={`owl-theme ${isRTL ? 'rtl' : ''}`}>
  const isRTL = Cookies.get('i18next').toLowerCase() == 'ar' ? true : false
  const Owloptions = {

    rtl: isRTL, // Enable RTL mode
    items: 2,
    // Add other Owl Carousel options as needed
  }
  // console.log(Owloptions, Cookies.get('i18next').toLowerCase() == 'ar')
  return (
    <Fragment>
      {/* {console.log("loader", showLoader)} */}
      {showLoader ? <Loader /> : <></>}
      <div
        className="reward_main reward_first_main custom_scroll mCustomScrollbar _mCS_1"
        id={
          showRedeem === true || showBundlesInfo === true || showInfo === true
            ? "popup_show_redeem"
            : ""
        }
      >
        <ToastContainer />
        {tagShowDobNstate === true ? (
          <DobNStates
            submitDobNstate={
              showRedeem === true
                ? submitDobNstateForRedeemItem
                : submitDobNstateForBuyItem
            }
            cancelDobNstate={cancelDobNstate}
            screen={
              showRedeem === true
                ? dobNstateScreenNameRedeemItem
                : dobNstateScreenNameBuyItem
            }
          />
        ) : (
          ""
        )}
        <div className="reward_title">
          <div className="row">
            <div className="back-btn col-2 text-center"></div>
            <h3
              className="col-8 text-center"
              style={{
                marginBottom: "20px",
                fontSize: "20px",
                fontWeight: "1000",
              }}
            >
              {t('coin_store.reward_store')}
            </h3>
            {/* <div className="wallet-btn col-2">
              {helperFunc.getUserCountry()?.code === "IN" ? (
                <a onClick={() => goback()} style={{ cursor: "pointer" }}>
                  <img src={AccountIcon} alt="" />
                </a>
              ) : (
                ""
              )}
            </div> */}
          </div>

          <div className="row rewards_tab">
            <div
              className={`col-6 d-flex justify-content-center p-0 ${activeTab === 0 ? "active" : ""
                }`}
              onClick={() => {
                setActiveTab(0);
                fetchRewardsList()
                eventTracking(events.reward_store_menu);

              }}
            >
              <p style={{ width: "100%", fontWeight: "600" }}>{t('coin_store.rewards')}</p>
            </div>
            <div
              className={`col-6 d-flex justify-content-center p-0 ${activeTab === 1 ? "active" : ""
                }`}
              onClick={() => {
                setActiveTab(1);
                setActiveCoinStoreTab(0)
                fetchData()
                eventTracking(events.coin_store_menu);
              }}
            >
              <p style={{ width: "100%", fontWeight: "600" }}>{t('coin_store.coin_store')}</p>
            </div>
            {/* <div
              className={`col-4 d-flex justify-content-center p-0 ${activeTab === 2 ? "active" : ""
                }`}
              onClick={() => setActiveTab(2)}
            >
              <p style={{ width: "100%", fontWeight: "600" }}>{t('coin_store.earn_coins')}</p>
            </div> */}
          </div>

          <div className="row rewards_coin">
            <div
              className="d-flex justify-content-center align-items-center rewards-diamond"

            >
              <div
                className="text-white d-flex align-items-center fontSize10"
                style={{ fontWeight: "600" }}
              >
                <img
                  src={Diamond}
                  className="coin_img_medium icon-24"
                  alt="diamond"
                />{" "}
                {/* {localStorage.getItem("currentDiamond")} */}
                {
                walletDetails?.wallet?.filter((curr)=> curr?.currencyData?.code == process.env.REACT_APP_CURRENCY_CODE_OUT)?.[0]?.winningAmount
              }
              </div>
            </div>
            <div
              className="text-dark d-flex justify-content-center rewards-coin"

            >
              <div
                className="text-white d-flex align-items-center fontSize10"
                style={{ fontWeight: "600" }}
              >
                <img
                  src={Coin}
                  className="coin_img_medium icon-24"
                  alt="coin"
                />{" "}
                {/* {localStorage.getItem("currentCoin")} */}
                {
                walletDetails?.wallet?.filter((curr)=> curr?.currencyData?.code == process.env.REACT_APP_CURRENCY_CODE)?.[0]?.winningAmount
              }
              </div>
            </div>
          </div>

          {/* {activeTab === 0 &&
            <div className="rewards_coin">
              <div className="mt-4 bg-primary text-dark d-flex justify-content-center align-items-center"
                style={{ padding: '10px 40px', width: '190px', margin: 'auto', borderRadius: '50px' }}>
                <div className="text-dark d-flex align-items-center" style={{ fontSize: '22px', fontWeight: '600' }}>
                  <img src={Diamond} className="coin_img_medium" style={{ marginRight: '10px' }} />{" "}
                  {localStorage.getItem("currentDiamond")}
                </div>
              </div>
            </div>
          } */}
        </div>
        <div className="reward_second_main" style={{ paddingBottom: "30px" }}>
          {activeTab === 1 ? (
            <div
              className="all_sub_screens_div_related_to_ads"
              style={{ overflowY: "inherit", overflowX: "inherit" }}
            >
              <div
                className="list-group bg-primary main-tab  coin_reward_tabs"
                id="list-tab"
                role="tablist"
              >
                <span
                  className={`list-group-item list-group-item-action ${activeTab === 1 && activeCoinStoreTab === 0
                    ? "active"
                    : "text-dark"
                    }`}
                  id="list-home-list"
                  data-toggle="list"
                  // href="#list-1"
                  onClick={() => {
                    eventTracking(events.packs_tab); 
                    setActiveCoinStoreTab(0)
                    fetchData()

                  }}
                >
                  {t('coin_store.packs')}
                </span>
                <span
                  className={`list-group-item list-group-item-action ${activeTab === 1 && activeCoinStoreTab === 1
                    ? "active"
                    : "text-dark"
                    }`}
                  id="list-profile-list"
                  data-toggle="list"
                  // href="#list-2"
                  onClick={() => {
                    eventTracking(events.bundles_tab); 
                    setActiveCoinStoreTab(1);
                    fetchBundles()
                  }}
                >
                  {t('coin_store.bundles')}
                </span>
                <span
                  className={`list-group-item list-group-item-action ${activeTab === 1 && activeCoinStoreTab === 2
                    ? "active"
                    : "text-dark"
                    }`}
                  id="list-profile-list"
                  data-toggle="list"
                  // href="#list-3"
                  onClick={() => { 
                    eventTracking(events.avatars_tab); 
                    setActiveCoinStoreTab(2);
                    fetchAvatars()
                  }}
                >
                  {t('coin_store.avatars')}
                </span>
              </div>
              <div className="tab-content h-100" id="nav-tabContent">
                {/* {console.log(virtualItem?.length)}
                  {console.log(allCoin1?.length)}
                  {console.log(activeCoinStoreTab?.length, "fffffff")}
                  {console.log(activeTab?.length, "fff")} */}

                {activeTab === 1 && activeCoinStoreTab === 0 && (
                  <div className="tab-pane h-100 active mx-4 d-flex flex-column justify-content-between">
                    <div className="row" style={{ marginTop: "10px" }}>
                      {virtualItem?.length > 0 ? (
                        virtualItem?.map((item, index) => {
                          return (
                            <div
                              className="col-4 packs_main text-center"
                              style={{ padding: "5px" }}
                              key={`allCoin1_${index}`}
                            >
                              <div className="packs_first">
                                <img src={PurchasePackIcon} />
                                <p
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item?.description}
                                  {/* 200 */}
                                </p>
                                <p>{t('coin_store.coins')}</p>
                              </div>
                              {/* {console.log(">>>", currencySymbol)} */}
                              <div
                                className="packs_second"
                                // onClick={() =>
                                //   orderCreation(
                                //     item?.sku,
                                //     item?.price?.amount,
                                //     item?.description,
                                //     1
                                //   )
                                // }
                              >
                                {t('coin_store.buy')} {currencySymbol}{" "}
                                {/* {parseInt(item?.price?.amount)} */}
                                {item?.price?.amount}
                              </div>
                            </div>
                          );
                        })
                      ) : allCoin1 !== null ? (
                        allCoin1?.map((item, index) => {
                          return (
                            <div
                              className="col-4 packs_main text-center"
                              style={{ padding: "5px" }}
                              key={`allCoin1_${index}`}
                            >
                              <div className="packs_first">
                                <img src={PurchasePackIcon} />
                                <p
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item?.coins}
                                </p>
                                <p> {t('coin_store.coins')}</p>
                              </div>
                              <div
                                className="packs_second"
                                onClick={() => {onClickBuyItem(item);}}
                              >
                                {t('coin_store.buy')} {item?.currency?.symbol} {item?.amount}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        // <Loader />
                        <div
                          className="d-flex justify-content-center"
                          style={{}}
                        >
                          <div
                            style={{
                              padding: "20px",
                              // color: "#F92C2C",
                              fontSize: "18px",
                              fontWeight: "1000",
                            }}
                          >
                            {t('notFound.no_records_found')}
                          </div>
                        </div>
                      )}
                      {/* {console.log("cfOrderData==>", cfOrderData)} */}
                      <form
                        id="redirectForm"
                        method="post"
                        action="https://www.cashfree.com/checkout/post/submit"
                      >
                        <input
                          type="hidden"
                          name="appId"
                          value={cfOrderData?.appId}
                        />
                        <input
                          type="hidden"
                          name="orderId"
                          value={cfOrderData?.orderId}
                        />
                        <input
                          type="hidden"
                          name="orderAmount"
                          value={cfOrderData?.orderAmount}
                        />
                        <input
                          type="hidden"
                          name="orderCurrency"
                          value={cfOrderData?.orderCurrency}
                        />
                        <input
                          type="hidden"
                          name="orderNote"
                          value={cfOrderData?.orderNote}
                        />
                        <input
                          type="hidden"
                          name="customerName"
                          value={cfOrderData?.customerName}
                        />
                        <input
                          type="hidden"
                          name="customerEmail"
                          value={cfOrderData?.customerEmail}
                        />
                        <input
                          type="hidden"
                          name="customerPhone"
                          value={cfOrderData?.customerPhone}
                        />

                        <input
                          type="hidden"
                          name="returnUrl"
                          value={cfOrderData?.returnUrl}
                        />
                        <input
                          type="hidden"
                          name="notifyUrl"
                          value={cfOrderData?.notifyUrl}
                        />
                        <input
                          type="hidden"
                          name="signature"
                          value={cfOrderData?.signature}
                        />

                        {/* <input type="submit" value="Pay"></input> */}
                      </form>
                      {/* <form  id="redirectForm" method="post" action="https://www.cashfree.com/checkout/post/submit">
    <input type="hidden" name="appId" value="58874f6db6934ba819cab399247885"/>
    <input type="hidden" name="orderId" value="order00001"/>
    <input type="hidden" name="orderAmount" value="100"/>
    <input type="hidden" name="orderCurrency" value="INR"/>
    <input type="hidden" name="orderNote" value="test"/>
    <input type="hidden" name="customerName" value="John Doe"/>
    <input type="hidden" name="customerEmail" value="Johndoe@test.com"/>
    <input type="hidden" name="customerPhone" value="9999999999"/>
    
    <input type="hidden" name="returnUrl" value="https://web.gamerji.com/coin-store"/>
    <input type="hidden" name="notifyUrl" value="https://web.gamerji.com/coin-store"/>
    <input type="hidden" name="signature" value="TLhlf0ghma4qSq1WmCZ5G7cD8XUKFkCaQeAQHWL3QAk="/>
    
    <input type="submit" value="Pay"></input>
    </form> */}
                    </div>
                    <div
                      className="d-flex w-100 justify-content-between align-items-center bg-primary"
                      style={{
                        padding: "10px 20px",
                        borderRadius: "50px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        localStorage.setItem("myRecentTransactions", "coin");
                        navigate(routehelp.myRecentTransactions);
                      }}
                    >
                      <img src={myRecentTransactionImage} />
                      <p
                        className="m-0"
                        style={{ fontSize: "18px", fontWeight: "600" }}
                      >
                        {t('coin_store.my_recent_transactions')}
                      </p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21.334"
                        height="18.014"
                        viewBox="0 0 21.334 18.014"
                      >
                        <g
                          id="ico"
                          transform="translate(21.334 18.013) rotate(-180)"
                        >
                          <path
                            id="union"
                            d="M8.321,17.721.479,9.879A1,1,0,0,1,.125,8.541,1.005,1.005,0,0,1,.411,8.2L8.321.293A1,1,0,0,1,9.735,1.706L3.417,8.025H20.334a1,1,0,1,1,0,2H3.454l6.281,6.281a1,1,0,0,1-1.414,1.415Z"
                            transform="translate(0 0)"
                            fill="#000"
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                )}
                {/* {console.log(
                    "🚀 ~ file: coinStore.js:943 ~ onClickRedeemItem ~ activeCoinStoreTab:lalala",
                    activeCoinStoreTab
                  )}
                  {console.log(
                    "🚀 ~ file: coinStore.js:943 ~ onClickRedeemItem ~ activeTab",
                    activeTab
                  )} */}
                {activeTab === 1 && activeCoinStoreTab === 1 && (
                  <div className="tab-pane active mx-4">
                    <div className="row">
                      {/* {console.log(virtualItem, "vie")}
                        {console.log(allCoin1, "ff")} */}
                      {virtualItem?.length > 0 ? (
                        virtualItem.map((item, index) => (
                          <div
                            className="col-6 bundles_main text-center my-3"
                            key={`allCoin2_${index}`}
                          >
                            <div className="bg-design"></div>
                            <div className="bundles_first">
                              {/* {console.log(">>>image", item?.image_url)} */}
                              <img className="icon-20" src={item?.image_url} alt="avtar" />
                              <p
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  marginBottom: "0px",
                                  lineHeight: "2ch",
                                }}
                              >
                                <img src={
                          url.imageUrl + item?.currency?.img?.default
                        } className="coin_img_avatar" />
                                <span
                                  style={{ position: "relative", top: "2px" }}
                                >
                                  {" "}
                                  {item?.coins}{" "}
                                </span>
                                <small>{item?.currency?.code == process.env.REACT_APP_CURRENCY_CODE_OUT ? "Diamonds" :"Coins"}</small>
                              </p>
                              <small style={{ lineHeight: "2ch" }}>+</small>
                              <p style={{ lineHeight: "2ch" }}>{item?.avatar?.name}</p>
                            </div>
                            {item?.isPurchase ? (
                              <div className="bundles_second">{t('coin_store.bought')}</div>
                            ) : (
                              <div
                                className="bundles_second"
                                // onClick={() => {
                                //   // onClickBundlesInfo(item)
                                //   orderCreation(
                                //     item?.sku,
                                //     item?.price?.amount,
                                //     item?.description,
                                //     2
                                //   );
                                // }}
                              >
                                {t('coin_store.buy')} {currencySymbol}{" "}
                                {/* {JSON.parse(item?.price?.amount)} */}{" "}
                                {/* {parseInt(item?.price?.amount)} */}
                                {item?.price?.amount}
                              </div>
                            )}
                          </div>
                        ))
                      ) : allCoin2 !== null ? (
                        allCoin2?.map((item, index) => {
                          return (
                            <div
                              className="col-6 bundles_main text-center my-3"
                              key={`allCoin2_${index}`}
                            >
                              <div className="bg-design"></div>
                              <div className="bundles_first">
                                <img
                                  src={
                                    url.imageUrl + item?.avatar?.img?.default
                                  }
                                />
                                <p
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    marginBottom: "0px",
                                    lineHeight: "2ch",
                                  }}
                                >
                                  <img src={
                          // url.imageUrl + item?.currency?.img?.default
                          Coin
                        } className="coin_img_avatar" />
                                  <span
                                    style={{
                                      position: "relative",
                                      top: "2px",
                                    }}
                                  >
                                    {" "}
                                    {item?.coins}{" "}
                                  </span>
                                 {/* {console.log(item, process.env.REACT_APP_CURRENCY_CODE_OUT)} */}
                                  <small>{item?.currency?.code == process.env.REACT_APP_CURRENCY_CODE_OUT ? "Diamonds" :"Coins"}</small>
                                </p>
                                <small style={{ lineHeight: "2ch" }}>+</small>
                                <p style={{ lineHeight: "2ch" }}>
                                  {item?.avatar?.name}
                                </p>
                              </div>
                              {item.isPurchase ? (
                                <div className="bundles_second">{t('coin_store.bought')}</div>
                              ) : (
                                <div
                                  className="bundles_second"
                                  onClick={() => {onClickBundlesInfo(item);}}
                                >
                                  {t('coin_store.buy')} {item?.currency?.symbol}{" "}
                                  {item?.amount}
                                </div>
                              )}
                            </div>
                          );
                        })
                      ) : (
                        // <Loader />
                        <div
                          className="d-flex justify-content-center"
                          style={{}}
                        >
                          <div
                            style={{
                              padding: "20px",
                              // color: "#F92C2C",
                              fontSize: "18px",
                              fontWeight: "1000",
                            }}
                          >
                            {t('notFound.no_records_found')}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {activeTab === 1 && activeCoinStoreTab === 2 && (
                  <div className="tab-pane active mx-4">
                    <div className="avatars_main">
                      {allCoin3 !== null ? (
                        allCoin3?.map((item, index) => {
                          return (
                            <div
                              className="avatar_cat_main row my-3"
                              key={`allCoin3_${index}`}
                            >
                              <img
                                src={url.imageUrl + item?.icon?.default}
                                className="avtara_cat_img"
                              />{" "}
                              <h4 className="avatar_cat_name">{item?.name}</h4>
                              <OwlCarousel
                                // className={`owl-theme ${isRTL ? 'rtl' : ''}`}
                                className={`main-slider owl-carousel owl-theme ${isRTL ? 'rtl' : ''}`}
                                autoWidth={false}
                                items={2}
                                loop={false}
                                margin={5}
                                stagePadding={50}
                                autoplay={false}
                                dots={false}
                                nav={true}
                                {...Owloptions}
                              // rtl={true}
                              >
                                {item?.avatars?.length &&
                                  item?.avatars?.map((item1, index1) => {
                                    return (
                                      <div
                                        className="item"
                                        style={{ height: "100%" }}
                                        key={`item_avatars_${index}_${index1}`}
                                      >
                                        <div className="bg-design-avatars"></div>
                                        <div className="bundles_first">
                                          <img
                                            src={
                                              url.imageUrl + item1?.img?.default
                                            }
                                          />
                                          <p className="text-center my-2">
                                            <b>{item1?.name}</b>
                                          </p>
                                        </div>
                                        {!item1.isPurchase ? (
                                          <div
                                            className="bundles_second"
                                            style={{ textAlign: "center" }}
                                          >
                                            {
                                              JSON.parse(localStorage.getItem("premiumUser")) ?
                                                <p
                                                  style={{
                                                    display: "-webkit-inline-box",
                                                    color: "#fff",

                                                  }}

                                                >
                                                  <span>{t('coin_store.unlocked')}</span>

                                                </p>
                                                :
                                                <p
                                                  style={{
                                                    display: "-webkit-inline-box",
                                                    color: "#fff",
                                                  }}
                                                  onClick={() => {onClickInfo(item1);}}
                                                >
                                                  <span>{t('coin_store.buy')}</span>
                                                  <img
                                                    // src={
                                                    //   url.imageUrl + item?.currency?.img?.default
                                                    // }
                                                    src={Coin}
                                                    className="coin_img mx-1"
                                                  />
                                                  <span>{item1?.coinAmount}</span>
                                                </p>
                                            }

                                          </div>
                                        ) : (
                                          <div
                                            className="bundles_second"
                                            style={{ textAlign: "center" }}
                                          >
                                            <p
                                              style={{
                                                display: "-webkit-inline-box",
                                                color: "#fff",
                                              }}
                                            >
                                              <span>{t('coin_store.bought')}</span>
                                              {/* <img
                                                  src={Coin}
                                                  className="coin_img mx-1"
                                                />
                                                <span>{item1?.coinAmount}</span> */}
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                              </OwlCarousel>
                            </div>
                          );
                        })
                      ) : (
                        // <Loader />
                        <div
                          className="d-flex justify-content-center"
                          style={{}}
                        >
                          <div
                            style={{
                              padding: "20px",
                              // color: "#F92C2C",
                              fontSize: "18px",
                              fontWeight: "1000",
                            }}
                          >
                            {t('notFound.no_records_found')}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : activeTab === 0 ? (
            <div
              className="all_sub_screens_div_related_to_ads"
              style={{ overflowY: "inherit", overflowX: "inherit" }}
            >
              <div
                className="list-group bg-primary main-tab coin_reward_tabs"
                id="list-tab"
                role="tablist"
              >
                <span
                  className={`list-group-item list-group-item-action ${activeTab === 0 && activeReward === 0
                    ? "active"
                    : "text-dark"
                    }`}
                  id="list-home-list"
                  data-toggle="rewardlist"
                  // href="#rewardlist-1"
                  onClick={() => {
                    setActiveReward(0); 
                    fetchRewardsList()
                    eventTracking(events.reward_tab);
                  }}
                >
                  {t('coin_store.rewards')}
                </span>
                <span
                  className={`list-group-item list-group-item-action ${activeTab === 0 && activeReward === 1
                    ? "active"
                    : "text-dark"
                    }`}
                  id="list-profile-list"
                  data-toggle="list"
                  // href="#rewardlist-2"
                  onClick={() => {
                    setActiveReward(1); 
                    getMyRewardList()
                    eventTracking(events.my_reward_tab);
                  }}
                >
                  {t('reward_store.my_rewards')}
                </span>
              </div>
              <div
                className="tab-content"
                style={{
                  display: "grid",
                  // placeItems: "center"
                }}
                id="nav-tabContent"
              >
                {activeTab === 0 && activeReward === 0 && (
                  <div className={`active mx-4`}>
                    <div className="allReward1_main" style={{ position: 'relative' }}>
                      {allReward1 !== null ? (
                        allReward1?.length > 0 ? (
                          allReward1?.map((item, index) => {
                            return (
                              <div
                                className="reward_cat_main row my-3"
                                key={`allReward1_${index}`}
                              >
                                <img
                                  src={url.imageUrl + item?.img?.default}
                                  className="avtara_cat_img"
                                  style={{
                                    position: "relative",
                                    top: "-5px",
                                  }}
                                />{" "}
                                <h4 className="reward_cat_name fontSize16">
                                  <span>{item?.name}</span>
                                </h4>
                                <OwlCarousel
                                  className="main-slider owl-carousel owl-theme"
                                  autoWidth={true}
                                  items={2}
                                  loop={false}
                                  margin={15}
                                  stagePadding={50}
                                  autoplay={false}
                                  dots={false}
                                  nav={true}
                                  {...Owloptions}
                                // rtl="true"
                                >
                                  {item?.products?.length &&
                                    item?.products?.map((item1, index1) => {
                                      return (
                                        <div
                                          className="item"
                                          style={{
                                            width: "170px",
                                            height: "170px",
                                            paddingBottom: "5px",
                                            border: "1px solid #000",
                                            borderRadius: "25px",
                                          }}
                                          key={`item_products_${index}_${index1}`}
                                        >
                                          <div className="reward1_first">
                                            <img
                                              style={{
                                                width: "100%",
                                                height: "48%",
                                                objectFit: "cover",
                                              }}
                                              src={
                                                url.imageUrl +
                                                item1?.img?.default
                                              }
                                              alt="img"
                                            />
                                            <div className="w-100 d-flex">
                                              <p
                                                className=""
                                                style={{
                                                  marginTop: 2,
                                                  marginLeft: 5,
                                                }}
                                              >
                                                {item1?.name?.length > 18 ? (
                                                  <marquee
                                                    style={{
                                                      marginBottom: -5,
                                                    }}
                                                  >
                                                    <b>{item1?.name}</b>
                                                  </marquee>
                                                ) : (
                                                  <b>{item1?.name}</b>
                                                )}
                                              </p>
                                            </div>
                                            <div
                                              className="w-100 d-flex"
                                              style={{ marginTop: 0 }}
                                            >
                                              <p
                                                className="col-6 mb-1"
                                                style={{ marginLeft: 0 , padding:'0 5px'}}
                                              >
                                                {/* {console.log(item1.country.currency?.symbol)} */}
                                                {item1.isVoucherAmountShow ? (
                                                  <b className="d-flex">
                                                    {/* {
                                                      item1.country.currency?.symbol
                                                    } */}
                                                    {/* <img className="icon-20" src={url.imageUrl+item1.country.currency?.img?.default} alt="RS"/> */}
                                                    Rs.
                                                    {" "}
                                                    {item1.rewardVoucherAmount}
                                                  </b>
                                                ) : (
                                                  ""
                                                )}
                                              </p>
                                              <p
                                                className="col-6 mb-1"
                                                style={{
                                                  marginRight: 5,
                                                  marginLeft: -10,
                                                }}
                                              >
                                                <b className="d-flex align-items-center">
                                                  {/* {console.log(item1.currency.img.default)} */}
                                                  <img
                                                    src={
                                                      url.imageUrl +
                                                      item1.currency.img.default
                                                    }
                                                    style={{
                                                      height: 10,
                                                      width: 15,
                                                      marginRight: 5,
                                                    }}
                                                    alt="PKR"
                                                  />
                                                  {"  "}
                                                  {item1.coinAmount}
                                                </b>
                                              </p>
                                            </div>
                                            <div className="p-0">
                                              <p
                                                className="redeem_click text-white"
                                                onClick={() =>{
                                                  // console.log(item1);
                                                  onClickRedeem(item1);
                                                  eventTracking(events.redeem_rewards,
                                                    {EP_RewardsProductID:item1?._id, EP_ProdcutName:item1?.name,
                                                       EP_ProductAmount:item1?.rewardVoucherAmount, 
                                                      EP_Currency:item1?.currency?.code,
                                                       EP_Amount:item1?.coinAmount});}
                                                }
                                              >
                                                {t('reward_store.redeem')}
                                              </p>
                                            </div>
                                          </div>
                                          {/* <div className="reward1_gradient"></div> */}
                                          {/* <div className="reward1_second">
                                            <div className="d-flex flex-column">
                                              <div className="w-100 d-flex">
                                                <p className="">
                                                  <b>{item1?.name}</b>
                                                </p>
                                              </div>

                                              <div className="row w-100 d-flex m-0"
                                                style={{ backgroundColor: 'var(--dark)', }}
                                              >
                                                <div className="col-6 p-0 d-flex align-items-center">
                                                  <p
                                                    className="mx-2 pl-1 text-white"
                                                    style={{
                                                      display: "-webkit-inline-box",
                                                      lineHeight: "15px",
                                                    }}
                                                  >
                                                    <img
                                                      src={
                                                        url.imageUrl + item1.currency?.img?.default
                                                      }
                                                      className="mr-1"
                                                      style={{
                                                        width: "15px",
                                                        height: "15px",
                                                      }}
                                                    />
                                                    {item1?.coinAmount}
                                                  </p>
                                                </div>
                                                <div className="col-6 p-0">
                                                  <p
                                                    className="redeem_click text-white"
                                                    onClick={() =>
                                                      onClickRedeem(item1)
                                                    }
                                                  >
                                                    Redeem
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div> */}
                                        </div>
                                      );
                                    })}
                                </OwlCarousel>
                              </div>
                            );
                          })
                        ) : (
                          <div
                            className="d-flex justify-content-center"
                            style={
                              {
                                // position: "fixed",
                                // top: "50%",
                                // left: "26%",
                                // transform: "translate(-50%, -50%)",
                              }
                            }
                          >
                            <div
                              style={{
                                padding: "20px",
                                // color: "#F92C2C",
                                fontSize: "18px",
                                fontWeight: "1000",
                              }}
                            >
                              {t('notFound.no_records_found')}
                            </div>
                          </div>
                        )
                      ) : (
                        // <Loader />
                        <div
                          className="d-flex justify-content-center"
                          style={{}}
                        >
                          <div
                            style={{
                              padding: "20px",
                              // color: "#F92C2C",
                              fontSize: "18px",
                              fontWeight: "1000",
                            }}
                          >
                            {t('notFound.no_records_found')}
                          </div>
                        </div>
                      )}

                   
                    </div>

                  </div>
                )}
                {/* {console.log(allReward2)} */}
                {activeTab === 0 && activeReward === 1 && (
                  <div className={`active mx-4"`} id="rewardlist-2">
                    <div className="allReward2_main">
                      {allReward2 !== null ? (
                        allReward2.length > 0 ? (
                          allReward2?.map((item, index) => {
                            if (item && "reward" in item) {
                              return (
                                <div
                                className="my_rewads_main text-light"
                                key={`allReward2_${index}`}
                                style={{border: "2px solid var(--dark-grey)"
                                           }}
                              >
                                {tagShowHowToRedeemPopup[index] &&
                                  createHowToRedeemPopup(
                                    item?.reward,
                                    index
                                  )}
                                <div
                                  className="row m-0 pb-2"
                                  style={{
                                    alignItems: "center",
                                  }}
                                >
                                  <div className="col-3 pt-1 d-flex justify-content-center" style={{paddingLeft:"0px"}}>
                                    <img src={url.imageUrl +item?.reward?.img?.default }className="coupe_img" alt="coupon" />
                                    {/* <img src={Myntra}className="coupe_img"/> */}
                                  </div>
                                  <div className={currentLang?.toLowerCase() == "ar" ? "col-9 " : "col-9 pl-0"}>
                                    <div className="row m-0 align-items-center justify-content-between">
                                      <h4
                                        className="mb-0 fontSize12 text-ellipsis"
                                        style={currentLang?.toLowerCase() == "ar" ? {
                                          fontWeight: "600",
                                          width: "80%",
                                          textAlign: "right",
                                          color:"var(--dark-black)"
                                        } : {
                                          fontWeight: "600",
                                          width: "68%",
                                          color:"var(--dark-black)"
                                        }}
                                      >
                                        {item?.reward?.name.length < 22 ? (
                                          item?.reward?.name
                                        ) : (
                                          <marquee>
                                            {item?.reward?.name}
                                          </marquee>
                                        )}
                                      </h4>
                                      <h5
                                        className=" text-center my_rewards_pending_failed_success_status text-white"
                                        style={{
                                          fontWeight: "500",
                                          padding:"7px 15px" ,
                                          fontSize:"0.75rem",
                                          display: "flex",
                                          marginTop:"-21px",
                                          marginRight:"-25px",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          backgroundColor: item?.deliveryStatus?.toLowerCase() ===
                                          "delivered" ? "var(--success)" : item?.deliveryStatus?.toLowerCase() ===
                                          "pending" ? "var(--dark-black2)" : "var(--secondary)"
                                        }}
                                      >
                                        {/* {item?.deliveryStatus?.toLowerCase() ===
                                          "delivered" && (
                                            <img
                                              src={SuccessPayment}
                                              className={currentLang?.toLowerCase() == "ar" ? "coin_img_myreward ml-1" : "coin_img_myreward mr-1"}
                                            />
                                          )}
                                        {item?.deliveryStatus?.toLowerCase() ===
                                          "pending" && (
                                            <img
                                              src={PendingPayment}
                                              className={currentLang?.toLowerCase() == "ar" ? "coin_img_myreward ml-1" : "coin_img_myreward mr-1"}
                                            />
                                          )}
                                        {item?.deliveryStatus?.toLowerCase() ===
                                          "PENDING" && (
                                            <img
                                              src={PendingPayment}
                                              className={currentLang?.toLowerCase() == "ar" ? "coin_img_myreward ml-1" : "coin_img_myreward mr-1"}
                                            />
                                          )}
                                        {item?.deliveryStatus?.toLowerCase() ==
                                          "failed" && (
                                            <img
                                              src={FailPayment}
                                              className={currentLang?.toLowerCase() == "ar" ? "coin_img_myreward ml-1" : "coin_img_myreward mr-1"}
                                            />
                                          )} */}
                                          {" "}
                                        {item?.deliveryStatus?.toLowerCase() ===
                                          "delivered" && t('reward_store.success')}
                                        {item?.deliveryStatus?.toLowerCase() ===
                                          "pending" && t('reward_store.inProgress')}
                                        {item?.deliveryStatus?.toLowerCase() ===
                                          "PENDING" && t('reward_store.inProgress')}
                                        {item?.deliveryStatus?.toLowerCase() ===
                                          "failed" && t('reward_store.failed')}
                                      </h5>
                                    </div>
                                    {currentLang?.toLowerCase() == "ar" ?
                                      <div className="row align-items-center">
                                        <div
                                          className=""
                                          style={{
                                            borderLeft: "1px solid #fff",
                                          }}
                                        >
                                          <h5
                                            className="text-center d-flex align-items-center fontSize12"
                                            style={{
                                              fontWeight: "600",
                                              paddingLeft: "20px",
                                              color:"var(--dark-black)"
                                            }}
                                          >
                                            <img
                                              src={
                                                url.imageUrl +
                                                item?.currency.img.default
                                              }
                                              className="coin_img_myreward mr-2"
                                              alt="coin"
                                            />{" "}
                                           <span> {item?.reward?.coinAmount}</span>
                                          </h5>
                                        </div>
                                        <small style={{ paddingRight: "20px" }}>
                                          {getConvertionDateTime(
                                            item?.createdAt
                                          )}
                                        </small>
                                      </div>
                                      :
                                      <div className="row align-items-center">
                                        <div
                                          className=""
                                          style={{
                                            borderRight: "2px solid var(--secondary)",
                                          }}
                                        >
                                          <h5
                                            className="d-flex align-items-center fontSize10"
                                            style={{
                                              fontWeight: "600",
                                              paddingRight: "5px",
                                              color:"var(--dark-black)",
                                              paddingLeft:"17px",
                                              marginBottom:"0px"
                                            }}
                                          >
                                            <img
                                              src={
                                                url.imageUrl +
                                                item?.currency.img.default
                                              }
                                              className="coin_img_myreward mr-2"
                                              alt="coin"
                                            />{" "}
                                            {item?.reward?.coinAmount}
                                          </h5>
                                        </div>
                                        <small style={{ paddingLeft: "10px",color:"var(--dark-black)", fontSize:"0.9rem", fontWeight:"600" }}>
                                          {getConvertionDateTime(
                                            item?.createdAt
                                          )}
                                        </small>
                                      </div>
                                    }
                                  </div>
                                </div>
                                <div
                                  className="row m-0 reward_coin_second align-items-center"
                                  style={{
                                    marginLeft:"10px !important",
                                    border: "2px solid var(--color_grey)",
                                    marginTop:"5px",
                                    padding:"7px",
                                    paddingTop:"5px",
                                    paddingBottom:"5px",
                                    borderRadius: "22px",
                                    // fontWeight: "700",
                                    fontSize:"0.82rem",
                                    backgroundColor:'var(--input-dark)',
                                    color:"var(--dark-black)"
                                  }}
                                >
                                  <div className="col-10 p-0">
                                    <div className="d-flex align-items-center p-1">
                                      {item.redeemVoucherCode.length < 36 ? (
                                        item.redeemVoucherCode
                                      ) : (
                                        <marquee>
                                          {item.redeemVoucherCode}
                                        </marquee>
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className="col-2 p-0 text-white"
                                    style={{
                                      borderRadius: "15px",
                                      cursor: "pointer",
                                      backgroundColor:"var(--secondary)"
                                    }}
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        item?.redeemVoucherCode
                                      );
                                      toast.info(t('info_Copied'), {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                      });
                                    }}
                                  >
                                    {item?.deliveryStatus === "FAILED" ? (
                                      <></>
                                    ) : (
                                      <div className="d-flex align-items-center p-1 justify-content-center">
                                        {t('coin_store.copy')}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {item?.deliveryStatus === "FAILED" ? (
                                  <></>
                                ) : (
                                  <div
                                    className="row w-100 align-items-center justify-content-center howToRedeem"
                                    
                                    onClick={() => {
                                      let temp = [...tagShowHowToRedeemPopup];
                                      temp[index] = true;
                                      setTagShowHowToRedeemPopup(temp);
                                    }}
                                  >
                                    {t('coin_store.how_to_redeem')}
                                  </div>
                                )}
                              </div>
                              );
                            }
                          })
                        ) : (
                          <div
                            className="d-flex justify-content-center"
                            style={{}}
                          >
                            <div
                              style={{
                                padding: "20px",
                                // color: "#F92C2C",
                                fontSize: "18px",
                                fontWeight: "1000",
                              }}
                            >
                              {t('notFound.no_records_found')}
                            </div>
                          </div>
                        )
                      ) : (
                        // <Loader />
                        <div
                          className="d-flex justify-content-center"
                          style={{}}
                        >
                          <div
                            style={{
                              padding: "20px",
                              // color: "#F92C2C",
                              fontSize: "18px",
                              fontWeight: "1000",
                            }}
                          >
                            {t('notFound.no_records_found')}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <EarnCoins />
          )}

          {
            !JSON.parse(localStorage.getItem("premiumUser")) &&

            <Advertisement
              screen={
                localStorage.getItem("apk_screens_list") === null
                  ? { code: "no_results" }
                  : JSON.parse(localStorage.getItem("apk_screens_list")).coinStore
              }
              screen_div_class_name=".all_main_screens_div_related_to_ads"
              screen_type="main"
            />
          }



         
          

        </div>


      </div>
      {showInfo === true && (
       <div
              className="custom-popup home-featured show redeem_popup bg-primary"
              id="contact_popup1"
            >
              <div className="popup-head bg-primary">
                <button
                  className="close-popup"
                  onClick={() => setShowInfo(false)}
                >
                  <img src={Close} alt="" />
                </button>
              </div>
              <div className="popup-body text-center mb-3">
                <div className="bundles_first">
                  <img
                    src={url.imageUrl + avatarData?.img?.default}
                    style={{ height: "250px" }}
                    alt="avatar"
                  />
                  <p className="text-center my-2 avatar-title-box">
                    <b>{avatarData?.name}</b>
                  </p>
                </div>
                <div
                  className="bundles_second_pop"
                  style={{ textAlign: "center" }}
                >
                  <p
                    style={{
                      display: "-webkit-inline-box",
                      color: "#fff",
                    }}
                    onClick={() => OnClickBuyAvatar(avatarData._id)}
                  >
                    <img src={Coin} className="coin_img mx-1" />

                    <span>{avatarData?.coinAmount}</span>
                  </p>
                </div>
              </div>
            </div>
      )}
      {showBundlesInfo === true && (
            <div
              className="custom-popup home-featured show redeem_popup bg-primary"
              id="contact_popup1"
            >
              <div className="popup-head bg-primary">
                <button
                  className="close-popup"
                  onClick={() => setShowBundlesInfo(false)}
                >
                  <img src={Close} alt="" />
                </button>
              </div>
              <div className="popup-body text-center mb-3">
                <div className="bundles_first">
                  <img
                    src={url.imageUrl + bundlesData?.avatar?.img?.default}
                    style={{ height: "250px" }}
                  />
                  <p className="text-center my-2 bundle-title-box">
                    <p>
                      {/* <b className="bundle-title-p">{bundlesData.name}</b>{" "} */}
                    </p>
                    <b>
                      <img
                        src={
                          // url.imageUrl + bundlesData?.currency?.img?.default
                          Coin
                        }
                        className="coin_img mx-1"
                        style={{
                          width: "20px",
                        }}
                        alt="Coin"
                      />{" "}
                      {bundlesData?.coins} {bundlesData?.currency.symbol == process.env.REACT_APP_CURRENCY_CODE_OUT ? "Diamonds" :"Coins"} + {bundlesData?.avatar?.name}
                    </b>
                  </p>
                </div>
                <div
                  className="bundles_second_pop"
                  style={{ textAlign: "center" }}
                  onClick={() => onClickBuyItem2(bundlesData)}
                >
                  {t('coin_store.buy')} {bundlesData?.currency?.symbol} {bundlesData?.amount}
                </div>
              </div>
            </div>
      )}
      {showRedeem === true && (
                        <div
                          className="custom-popup home-featured show redeem_popup bg-light"
                          style={currentLang?.toLowerCase() == "ar" ? {
                            right: "90.5rem"
                          } : {

                          }}
                          id="contact_popup"
                        >
                          <div
                            className="popup-head "
                            style={{ margin: "-20px -20px 0px" ,
                            backgroundColor:"var(--primary)" }}
                          >
                            <h3>{redeemData?.name}</h3>
                            <button
                              className="close-popup"
                              onClick={() => setShowRedeem(false)}
                            >
                              <img src={Close} alt="" />
                            </button>
                          </div>
                          <div className="popup-body text-center mt-5">
                            <div className="all_sub_screens_div_related_to_ads">
                              <p className="">
                                <img
                                  src={url.imageUrl + redeemData?.img}
                                  className="coin_img30"
                                />{" "}
                                <b className="profile-redeem-popup-font">{redeemData?.coinAmount}</b>
                              </p>
                              <p className="mb-2">{redeemData?.description}</p>
                              <button
                                className="btn btn-primary"
                                style={{
                                  width: "100%",
                                  textTransform: "capitalize",
                                 marginTop:"20px",
                                 marginBottom:"20px",
                                  whiteSpace: "nowrap",
                                backgroundColor:"var(--dark)",
                                color:"var(--light)",
                                display:"flex",
                                flexDirection:"row",
                                justifyContent:"space-between"
                                }}
                                onClick={onClickRedeemItem}
                              >
                              <span>{t('reward_store.redeem')} </span>
                              <span > <img
                                  src={Arrow}
                                  className="coin_img20"
                                />{" "}</span>
                             
                              </button>
                            </div>
                            {
                              !JSON.parse(localStorage.getItem("premiumUser")) &&

                              <Advertisement
                                screen={
                                  localStorage.getItem("apk_screens_list") === null
                                    ? { code: "no_results" }
                                    : JSON.parse(localStorage.getItem("apk_screens_list"))
                                      .redeemRewardsPopup
                                }
                                screen_div_class_name=".all_sub_screens_div_related_to_ads"
                                screen_type="sub"
                              />
                            }
                          </div>
                        </div>
      )}
      {isQrCode && QrCodePopUp()}
      {showPayNow === true && (
          <CreatePayNow transactionId={transactionId} createTimeTn={createTimeTn} resultCode={resultCode} message={message} amount={coinAmount} symbol={symbol} /> 
      )}
    </Fragment>
  );
}

export default CoinStore;
